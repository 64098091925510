import Axios from 'axios';
import { isObject } from 'lodash';
import { objectToQuerystring } from '../utils';

import * as httpService from './http.service';

import appConfig from '../config';

// Remove values that are less than -998
const filterValues = (data) => {
  if (data.code) {
    return [];
  }
  if (data.length <= 0) { return []; }

  const newData = [];
  data.forEach((ds) => {
    const newParams = [];
    ds.parameters.forEach((item) => {
      const newObs = item.observations.filter((obs) => obs.value > -998);
      newParams.push({ parameter_id: item.parameter_id, observations: newObs });
    });
    newData.push({ obs_dataset_id: ds.obs_dataset_id, parameters: newParams });
  });
  return newData;
};

export const getObsData = (filter) => {
  const queryString = isObject(filter) ? objectToQuerystring(filter) : `startDate=${filter}`;

  return httpService
    .get(`/v1/obs?${queryString}`)
    .then(({ data }) => filterValues(data))
    .catch((err) => Promise.reject(err));
};

export const getObsLatestData = () => httpService
  .get('/v2/obs-latest')
  .then(({ data }) => filterValues(data))
  .catch((err) => Promise.reject(err));

export const getObsDataV2 = (filter) => {
  const queryString = isObject(filter) ? objectToQuerystring(filter) : `startDate=${filter}`;
  return httpService
    .get(`/v2/obs?${queryString}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getObsDataV2ById = (startDate, obsDatasetId) => httpService
  .get(`/v2/obs?startDate=${startDate}&obsDatasetId=${obsDatasetId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getObsDatasetsGeojson = () => httpService
  .get('/v1/obs-datasets.geojson')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getObsDatasetsPlatformGeojson = (platformId) => httpService
  .get(`/v1/obs-datasets.geojson?platformId=${platformId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getObsDatasetsOrgGeojson = (orgId) => httpService
  .get(`/v1/obs-datasets.geojson?orgId=${orgId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getParameters = () => httpService
  .get('/v1/parameters')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const datasetTrajectoriesGeojson = () => httpService
  .get('/v1/obs-dataset-trajectories.geojson')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const datasets = () => httpService
  .get('/v1/obs-datasets')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getDatasetsLatest = (filter) => {
  const queryString = objectToQuerystring(filter);

  return httpService
    .get(`/v1/obs-datasets-latest?${queryString}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getDatasetSummaries = (filter) => {
  const queryString = isObject(filter) ? `?${objectToQuerystring(filter)}` : '';

  return httpService
    .get(`/v1/obs-dataset-summaries${queryString}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const parameterConfigurations = () => httpService
  .get('/v1/parameter-configurations')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getRegisteredParameters = () => httpService
  .get('/v1/parameters')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const searchData = (query) => httpService
  .get(`/v1/search?query=${query}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getPlatformMetadata = (id) => httpService
  .get(`/v1/obs-datasets/${id}/metadata`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getPlatformSponsors = () => Axios
  .get('/data/sponsors.json')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getPlatformWeather = () => Axios
  .get('/data/weather.json')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getAllNonFunctionalDatasets = () => Axios
  .get('/data/dataset-detail.json').then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const downloadNonFunctionalDataset = (id, filename) => httpService
  .get(`/v1/datasets/${id}/files?filename=${filename}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getHostedNonFunctionalDataset = (datasetId) => httpService
  .get(`/v1/datasets/${datasetId}`).then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getNonFunctionalMetadata = (metadataId) => httpService.getMetaDataFromGeoPortal(`/rest/metadata/item/${metadataId}`);

export const searchNonFunctionalMetadata = (searchText, searchFilters, start, size = 10) => {
  let textQuery = '';
  if (searchText && searchText.length > 0) {
    textQuery = searchText.toLowerCase();
  }

  let filterQuery = '';
  if (searchFilters && searchFilters.length > 0) {
    const filterCons = searchFilters.map((filter) => {
      let newFilter = '';
      newFilter += `\\"${filter.toLowerCase()}\\"`;
      return newFilter;
    });

    filterQuery = filterCons.join(' ');
  }

  const query = `${textQuery} ${filterQuery}`;

  const esdslQuery = {
    query: {
      bool: {
        must_not: [
          {
            exists: {
              field: 'seagull_obs_dataset_id_i',
            },
          },
        ],
        must: [],
      },
    },
  };

  if (query.trim().length > 0) {
    esdslQuery.query.bool.must = [
      {
        query_string: {
          analyze_wildcard: true,
          query: `${query}`,
        },
      },
    ];
  }

  // eslint-disable-next-line
  // const appendedUrl = encodeURIComponent(`{"query":{"bool":{"must":[{"query_string":{"analyze_wildcard":true,"query":"${query}"}}],"must_not":[{"exists":{"field":"seagull_obs_dataset_id_i"}}]}}}`);
  return httpService.getMetaDataFromGeoPortal(`/opensearch?f=json&from=${start}&size=${size}&sort=title.sort:asc&esdsl=${encodeURIComponent(JSON.stringify(esdslQuery))}`);
};

export const getOrganizationDatasets = (organizationName, from = 1) => {
  if (!organizationName) return null;

  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              contact_organizations_s: organizationName,
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: 'seagull_obs_dataset_id_i',
            },
          },
        ],
        // ^ I'm not sure why we were excluding datasets with a seagull_obs_dataset_id_i field...
        // but we may want to remove this
      },
    },
  };

  return Axios
    .get(`${appConfig.metadataURL}/opensearch?f=json&from=${from}&size=99&esdsl=${encodeURIComponent(JSON.stringify(query))}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};
