import React from 'react';
import Qty from 'js-quantities';
import moment from 'moment';
import { pinnedParams } from '../contexts/UserContext';
import {
  AirTempIcon, WaterTempIcon, WaveHeightIcon, WindSpeedIcon,
} from '../components/icons';
import storageService from '../services/storage.service';
import { parameterGroups } from '../config/parameterGroups';

export const mediaBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const getCurrentDirectionValue = (eastwardVelocity, northwardVelocity) => Math.abs((Math.atan(eastwardVelocity / northwardVelocity) * 180) / Math.PI) + 180;
export const getCurrentSpeedValue = (eastwardVelocity, northwardVelocity) => Math.sqrt(((eastwardVelocity) ** 2) + ((northwardVelocity) ** 2)) * 100;

/**
 * Computes a "derived" current direction observation from eastward and northward velocity obs
 *
 * @param {object} eastwardVelocityObs
 * @param {object} northwardVelocityObs
 * @returns {object} directionObs
 */
export const getCurrentDirectionObs = (eastwardVelocityObs, northwardVelocityObs) => {
  // check specifically undefined or null because 0 is falsey, but shouldn't get caught
  if (eastwardVelocityObs?.value === null || eastwardVelocityObs?.value === undefined
    || northwardVelocityObs?.value == null) {
    return null;
  }

  const directionObs = { ...eastwardVelocityObs };

  directionObs.value = getCurrentDirectionValue(eastwardVelocityObs.value, northwardVelocityObs.value);

  return directionObs;
};

/**
 * Computes a "derived" current speed observation from eastward and northward velocity obs
 *
 * @param {object} eastwardVelocityObs
 * @param {object} northwardVelocityObs
 * @returns {object} speedObs
 */
export const getCurrentSpeedObs = (eastwardVelocityObs, northwardVelocityObs) => {
  // check specifically undefined or null because 0 is falsey, but shouldn't get caught
  if (eastwardVelocityObs?.value === null || eastwardVelocityObs?.value === undefined
    || northwardVelocityObs?.value == null) {
    return null;
  }

  const speedObs = { ...eastwardVelocityObs };

  speedObs.value = getCurrentSpeedValue(eastwardVelocityObs.value, northwardVelocityObs.value);

  return speedObs;
};

export const getPreferredUnit = (canonicalUnit, parameterConfig, unitPreferences) => {
  if (!parameterConfig) { return null; }

  if (unitPreferences && Array.isArray(unitPreferences)) {
    const userValue = unitPreferences?.find((p) => p.name === parameterConfig.standard_name);
    if (userValue) {
      return userValue.value;
    }
  } else {
    // eslint-disable-next-line no-console
    console.info(`NOTE: unitPreferences is not an object: ${unitPreferences}`);
  }
  return (canonicalUnit === 'kelvin' || canonicalUnit === 'fahrenheit' || canonicalUnit === 'celsius')
    ? (storageService.getItem('userPreferences').temp_preference === 'fahrenheit' ? parameterConfig?.preferred_imperial_unit_id : parameterConfig?.preferred_metric_unit_id)
    : (storageService.getItem('userPreferences').unit_preference === 'imperial' ? parameterConfig?.preferred_imperial_unit_id : parameterConfig?.preferred_metric_unit_id);
};

export const getUnitDisplayName = (parameterConfiguration, preferredUnit) => {
  if (!parameterConfiguration) { return null; }
  return parameterConfiguration?.units.find((o) => o.id === preferredUnit)?.symbol;
};

const normalizeCurrentSpeed = (parameter, parametersArr) => {
  let observationsResult = [];
  let northwardObs = [];
  let eastwardObs = [];

  if (parameter?.derived_name === 'current_speed' || parameter?.derived_name === 'current_direction') {
    northwardObs = parametersArr.find((o) => o.parameter_id === parameter?.northward_sea_water_velocity_id);
    eastwardObs = parametersArr.find((o) => o.parameter_id === parameter?.eastward_sea_water_velocity_id);
  }

  if (parameter?.derived_name === 'current_speed') {
    if (eastwardObs?.observations.length > 0 && northwardObs?.observations.length > 0) {
      const currentObs = {};
      currentObs.observations = eastwardObs?.observations.map((element, index) => getCurrentSpeedObs(eastwardObs.observations[index], northwardObs.observations[index]));
      observationsResult.push(currentObs);
    }
  } else if (parameter?.derived_name === 'current_direction') {
    if (eastwardObs?.observations.length > 0 && northwardObs?.observations.length > 0) {
      const currentObs = {};
      currentObs.observations = eastwardObs?.observations.map((element, index) => getCurrentDirectionObs(eastwardObs.observations[index], northwardObs.observations[index]));
      observationsResult.push(currentObs);
    }
  } else {
    observationsResult = parametersArr.filter((o) => o.parameter_id === parameter.parameter_id);
  }

  return observationsResult?.length > 0 ? observationsResult[0].observations : [];
};

// TODO: ensure that all data normalization is async chained from the query
export const normalizeParameterObs = (parameter, parametersArr) => (normalizeCurrentSpeed(parameter, parametersArr));

export const espStandardNames = [
  'microcystin_concentration',
  'microcystin_is_estimate',
  'microcystin_llod',
  'microcystin_lloq',
  'microcystin_gt_uloq',
  'microcystin_lt_llod',
];

/**
 * This will get all parameters of the supplied dataset
 *
 * @param {object}   dataset
 * @param {array}    datasetSummaries
 * @param {array}    obsData
 * @param {array}    parameterConfigurations
 * @param {array}    registeredParameters
 * @param {array}    unitPreferences
 * @param {boolean}  doTransform
 *
 * @returns array
 */
// TODO: all of the observation calculations, renaming, etc should be encapsulated elsewhere and used in all of the places that require it
export const getParameters = ({
  dataset, datasetSummaries, obsData, parameterConfigurations, registeredParameters, unitPreferences, doTransform = true,
}) => {
  const parameters = [];

  if (!dataset && !obsData && obsData?.code === 500 && !parameterConfigurations && !registeredParameters) {
    return parameters;
  }

  let parametersList = [];
  let targetPlatformId = -1;

  if (dataset.obs_dataset_platform_assignment !== undefined) {
    targetPlatformId = dataset.obs_dataset_platform_assignment.platform.platform_id;
  } else {
    const obsDataParameters = datasetSummaries.find((o) => o.org_platform_id === dataset?.org_platform_id);

    if (!obsDataParameters) {
      return parameters;
    }

    targetPlatformId = obsDataParameters.obs_dataset_platform_assignment.platform.platform_id;
  }

  if (targetPlatformId === -1) {
    return parameters;
  }

  parametersList = registeredParameters.filter((rp) => rp.platform_id === targetPlatformId);

  // Get icon from parameter name
  const getIcon = (name) => {
    if (name === undefined) {
      return '';
    }

    let icon;
    if (name?.includes('Water Temp')) {
      icon = <WaterTempIcon style={{ fontSize: 12 }} />;
    } else if (name?.includes('Wave Height')) {
      icon = <WaveHeightIcon />;
    } else if (name?.includes('Air Temp')) {
      icon = <AirTempIcon />;
    } else if (name?.includes('Wind Speed')) {
      icon = <WindSpeedIcon />;
    }

    return icon;
  };

  // Format the parameters
  let minDepth = 0;
  let maxDepth = 0;
  const childParameters = [];

  // create new array for transformations
  const currentsParametersList = parametersList.filter((parameter) => parameter.standard_name === 'northward_sea_water_velocity' || parameter.standard_name === 'eastward_sea_water_velocity');
  // remove items in new array
  parametersList = parametersList.filter((parameter) => parameter.standard_name !== 'northward_sea_water_velocity' && parameter.standard_name !== 'eastward_sea_water_velocity');

  const currentsParametersListTransformed = currentsParametersList.map((originalParam) => {
    const parameter = { ...originalParam };

    if (parameter.standard_name === 'eastward_sea_water_velocity') {
      parameter.derived_name = 'current_direction';
      parameter.eastward_sea_water_velocity_id = parameter.parameter_id;
      parameter.northward_sea_water_velocity_id = currentsParametersList.find((parameter) => parameter.parent_parameter_id === originalParam.parameter_id)?.parameter_id;
    } else if (parameter.standard_name === 'northward_sea_water_velocity') {
      parameter.derived_name = 'current_speed';
      parameter.eastward_sea_water_velocity_id = parameter.parent_parameter_id;
      parameter.northward_sea_water_velocity_id = parameter.parameter_id;
      parameter.parent_parameter_id = null; // so that it doesn't get processed as a child parameter
    }
    return parameter;
  });

  currentsParametersListTransformed.forEach((parameter) => {
    parametersList.push(parameter);
  });

  parametersList.forEach((parameter) => {
    // Ignore parameters if profiling is true
    if (parameter.profiling === false) {
      const parameterConfig = parameterConfigurations ? parameterConfigurations.find((o) => o.name_vocabulary === parameter.name_vocabulary && o.standard_name === parameter.standard_name) : null;

      const datasetParametersRes = obsData && obsData?.length > 0 ? obsData.find((o) => o.obs_dataset_id === dataset.obs_dataset_id) : null;

      const datasetParameters = datasetParametersRes ? datasetParametersRes.parameters : [];

      const parameterObservations = normalizeParameterObs(parameter, datasetParameters);

      const userLang = navigator.language.toLowerCase();
      const lang = userLang.split('-')[0];
      const canonicalUnit = parameterConfig?.canonical_unit_id;
      const preferredUnit = getPreferredUnit(canonicalUnit, parameterConfig, unitPreferences);

      // Canonical unit array
      const canonicalUnitObj = parameterConfig ? parameterConfig.units.find((o) => o.id === canonicalUnit) : null;

      // Preferred unit array
      const preferredUnitObj = parameterConfig ? parameterConfig.units.find((o) => o.id === preferredUnit) : null;

      let parameterValue = 0;
      let parameterDate = parameter.created_at;
      let parameterName = parameterConfig?.display_name[lang] ?? parameterConfig?.display_name?.en;
      const parameterDescription = parameterConfig?.display_description[lang] ?? parameterConfig?.display_description?.en;

      if (minDepth > parameter.depth) {
        minDepth = parameter.depth;
      }

      if (maxDepth < parameter.depth) {
        maxDepth = parameter.depth;
      }

      const parameterUnitIfNull = parameterConfig && parameterConfig.units.length > 0 ? parameterConfig.units[0].symbol : null;
      let parameterUnit = preferredUnitObj ? preferredUnitObj?.symbol : parameterUnitIfNull;

      if (parameterObservations) {
        const latestParameterObservation = parameterObservations[0];

        const configAlerts = parameterConfig?.alerts;
        let alertsThresold = configAlerts;

        if (parameter?.derived_name !== 'current_speed' && parameter?.derived_name !== 'current_direction' && doTransform === true) {
          try {
            // Convert to preferred unit
            parameterValue = Qty(latestParameterObservation.value, canonicalUnitObj.js_qty_unit).to(preferredUnitObj.js_qty_unit).scalar.toFixed(2);

            if (configAlerts) {
              alertsThresold = {
                threshold_max: Number(Qty(Number(configAlerts.threshold_max), canonicalUnitObj.js_qty_unit).to(preferredUnitObj.js_qty_unit).scalar.toFixed(2)),
                threshold_min: Number(Qty(Number(configAlerts.threshold_min), canonicalUnitObj.js_qty_unit).to(preferredUnitObj.js_qty_unit).scalar.toFixed(2)),
              };
            }
          } catch (e) {
            parameterValue = latestParameterObservation?.value?.toFixed(2) ?? null;
          }
        } else if (parameter?.derived_name === 'current_speed') {
          parameterValue = storageService.getItem('userPreferences').unit_preference === 'imperial'
            ? `${(latestParameterObservation?.value / 51.444).toFixed(2)}`
            : `${latestParameterObservation?.value.toFixed(2)}`;
        } else {
          parameterValue = latestParameterObservation?.value?.toFixed(2) ?? null;
        }

        // * 1000 to convert Unix timestamp in seconds to milliseconds for JavaScript/moment.js
        parameterDate = Number.isNaN(Number(latestParameterObservation?.timestamp))
          ? new Date(latestParameterObservation?.timestamp).getTime()
          : new Date(latestParameterObservation?.timestamp * 1000).getTime();

        if (parameter?.derived_name === 'current_speed') {
          parameterName = 'Current Speed';
          parameterUnit = storageService.getItem('userPreferences').unit_preference === 'imperial' ? 'kts' : 'cm/s';
        } else if (parameter?.derived_name === 'current_direction') {
          parameterName = 'Current Direction (from)';
          parameterUnit = '°';
        }

        const parameterData = {
          parameter_id: parameter.parameter_id,
          name: parameterName,
          derived_name: parameter?.derived_name,
          description: parameterDescription,
          value: Number(parameterValue),
          unit: parameterUnit,
          canonicalUnit: canonicalUnitObj?.js_qty_unit,
          preferredUnit: preferredUnitObj?.js_qty_unit,
          date: parameterDate,
          icon: getIcon(parameterName),
          name_vocabulary: parameter.name_vocabulary,
          standard_name: parameter.standard_name,
          parameter_name: parameter.parameter_name,
          depth: parameter.depth ?? 0,
          parent_parameter_id: parameter.parent_parameter_id,
          eastward_sea_water_velocity_id: parameter?.eastward_sea_water_velocity_id,
          northward_sea_water_velocity_id: parameter?.northward_sea_water_velocity_id,
          alerts: alertsThresold,
        };

        if (parameterValue) {
          if (parameter.parent_parameter_id) {
            childParameters.push({ ...parameterData, parent_parameter_id: parameter.parent_parameter_id });
          } else {
            parameters.push(parameterData);
          }
        }
      }
    } // if not profiling parameter
  });

  const pinnedPrefs = pinnedParams(unitPreferences);
  // split array into pinned and unpinned
  const pinned = parameters.filter((p) => pinnedPrefs.includes(p.standard_name)).map((parameter) => ({ ...parameter, pinned: true }));
  const unpinned = parameters.filter((p) => !pinnedPrefs.includes(p.standard_name));
  // Sort unpinned with depth name asc then name asc
  unpinned.sort((a, b) => a.depth - b.depth || (a.name || '').localeCompare(b.name || ''));
  const sorted = [...pinned, ...unpinned];
  return sorted.map((parameter) => ({
    ...parameter,
    children: childParameters.filter((child) => (
      child.parent_parameter_id && child.parent_parameter_id === parameter.parameter_id
    )),
  }));
};

export const formatParameterObservation = (parameterConfigurations, parameter, parameterObservation, unitPreferences) => {
  let parameterValue = 0;
  const parameterConfig = parameterConfigurations.find((o) => o.name_vocabulary === parameter?.name_vocabulary && o.standard_name === parameter?.standard_name);

  if (!parameterConfig) {
    return parameterObservation.value;
  }

  if (parameterObservation?.value < -998) {
    return null;
  }

  if (parameterObservation?.value === null || parameterObservation?.value === undefined) {
    return parameterValue;
  }

  if (parameter?.derived_name === 'current_speed') {
    return storageService.getItem('userPreferences').unit_preference === 'imperial'
      ? `${(parameterObservation.value / 51.444).toFixed(2)} kts`
      : `${parameterObservation.value.toFixed(2)} cm/s`;
  }

  if (parameter?.derived_name === 'current_direction') {
    return !Number.isNaN(parameterObservation.value) ? `${parameterObservation.value.toFixed(2)} °` : null;
  }

  const canonicalUnit = parameterConfig.canonical_unit_id;
  const preferredUnit = getPreferredUnit(canonicalUnit, parameterConfig, unitPreferences);

  // Canonical unit array
  const canonicalUnitObj = parameterConfig.units.find((o) => o.id === canonicalUnit);

  // Preferred unit array
  const preferredUnitObj = parameterConfig.units.find((o) => o.id === preferredUnit);

  const parameterUnit = preferredUnitObj?.symbol;

  try {
    // Convert to preferred unit
    parameterValue = Qty(parameterObservation.value, canonicalUnitObj.js_qty_unit).to(preferredUnitObj.js_qty_unit).scalar.toFixed(2);
  } catch (e) {
    parameterValue = parameterObservation.value ? parameterObservation.value.toFixed(2) : parameterObservation.value;
  }
  return parameterValue ? `${parameterValue} ${parameterUnit}` : null;
};

/**
 * Get the date of most recent observation for a dataset
 * @param {object}  dataset the obs dataset
 * @param {array}   obsData the observation data
 * @param {boolean} raw     indicate raw Epoch timestamp or relative time
 */
export const getLatestParameterDate = (dataset, obsData, raw = false) => {
  let latestDate;

  if (!obsData || obsData.length < 1) {
    return null;
  }

  const datasetObsData = obsData.find((o) => o.obs_dataset_id === dataset.obs_dataset_id);

  if (!datasetObsData) {
    return null;
  }

  const { parameters } = datasetObsData;

  parameters.forEach((parameter) => {
    // observations are returned from API ordered by timestamp DESC, so first item is newest
    const latestObservation = parameter.observations[0];

    // if latestDate is not set yet or the current parameter's latest observation is newer, update latestDate
    if (latestObservation
      && (!latestDate || (new Date(latestObservation.timestamp * 1000).getTime() > new Date(latestDate).getTime())
      )
    ) {
      // * 1000 to convert Unix timestamp in seconds to milliseconds for JavaScript/moment.js
      latestDate = latestObservation.timestamp * 1000;
    }
  });

  return raw ? latestDate : moment(latestDate).fromNow();
};

export const preferredTemperatureUnit = (value) => (
  storageService.getItem('userPreferences').temp_preference === 'fahrenheit'
    ? `${Math.round((((value) / 5) * 9) + 32)}°F`
    : `${Math.round(value)}°C`
);

export const celsiusTemperatureUnit = (value) => (
  `${Math.round(value)}°C`
);

export const fahrenheitTemperatureUnit = (value) => (
  `${Math.round((((value) / 5) * 9) + 32)}°F`
);

export const preferredWindMagnitudeUnit = (value) => (
  `${Math.round((value * 1.944 * 100) / 100)}kts`
);

export const preferredWindMagnitudeUnitLines = (value) => (
  `${Math.round(value * 1.944 * 100) / 100}\nkts`
);

export const preferredWaterMagnitudeUnit = (value) => (
  storageService.getItem('userPreferences').unit_preference === 'metric'
    ? `${Math.round((value * 100)) / 100}m/s`
    : `${Math.round(value * 3.28084 * 100) / 100}ft/s`
);

export const objectToQuerystring = (params) => Object.keys(params)
  .filter((key) => params[key])
  .map((key) => `${key}=${params[key]}`)
  .join('&');

export const platformGroupings = {
  buoy: ['moored_buoy', 'wave_buoy', 'profiling_buoy', 'buoy', 'fixed', 'profiler', 'mooring'],
  tower: ['offshore_tower', 'tower'],
  moving: ['submersible', 'automated_underwater_vehicle', 'glider', 'drifting_buoy'],
  sampling_location: ['sampling_location'],
  esp_site: ['esp_site'],
};

/**
 * Get a depth value in the preferred unit
 * @param {number} value
 * @returns {string} Depth in preferred unit, rounded to the nearest 1 decimal point, with unit symbol. Null if value is not a number.
 */
export const getPreferredMeasurementUnit = (value) => {
  const numberValue = Number(value);
  if (Number.isNaN(numberValue)) {
    return null;
  }

  // meters to feet conversion
  const CONVERSION = 3.280839895;
  const unitPreference = storageService.getItem('userPreferences').unit_preference;

  if (unitPreference === 'metric') {
    return `${numberValue.toFixed(1)} m`;
  }

  return `${(numberValue * CONVERSION).toFixed(1)} ft`;
};

export const convertParameterUnit = ({
  parameterConfig, value, unitPreferences, toCanonical = false, appendUnit = false,
}) => {
  const canonicalUnit = parameterConfig?.canonical_unit_id;
  const preferredUnit = getPreferredUnit(canonicalUnit, parameterConfig, unitPreferences);

  // Canonical unit array
  const canonicalUnitObj = parameterConfig ? parameterConfig.units.find((o) => o.id === canonicalUnit) : null;

  // Preferred unit array
  const preferredUnitObj = parameterConfig ? parameterConfig.units.find((o) => o.id === preferredUnit) : null;

  let frmUnit;
  let toUnit;

  if (toCanonical) {
    frmUnit = preferredUnitObj;
    toUnit = canonicalUnitObj;
  } else {
    frmUnit = canonicalUnitObj;
    toUnit = preferredUnitObj;
  }

  let newVal;

  if (canonicalUnit === preferredUnit) {
    newVal = value;
  } else {
    try {
      newVal = Qty(value, frmUnit.js_qty_unit).to(toUnit.js_qty_unit).scalar.toFixed(2);

      newVal = Number(newVal);
    } catch (e) {
      newVal = value;
    }
  }

  if (appendUnit) {
    if (toUnit) {
      return `${newVal} ${toUnit.symbol}`;
    }
    return `${newVal}`;
  }

  return newVal;
};

export const getParameterGroupIcon = (parameter) => {
  const parameterGroup = parameterGroups.find((group) => group.parameters.includes(parameter));

  if (!parameterGroup) {
    return null;
  }

  return parameterGroup.icon;
};

export const capitalizeFirstLetter = (inputString) => inputString.charAt(0).toUpperCase() + inputString.slice(1);

/**
 * Finds the most recent parameter
 * @param {Object} obsData - The obsDataset for the platform
 * @returns {Object} - Returns the most recent parameter
 */
export const findMostRecentParameter = (obsData) => {
  let recentIndex = 0;
  let recentTimestamp = 0;

  obsData?.parameters?.forEach(({ observations }, index) => {
    if (observations[0]) {
      const { timestamp: paramTimestamp } = observations[0];
      if (paramTimestamp > recentTimestamp) {
        recentTimestamp = paramTimestamp;
        recentIndex = index;
      }
    }
  });
  return obsData?.parameters[recentIndex];
};

/**
 * Gets the research workspace URL with the proper start & end dates for the selected platform
 * @param {Date} updatedDate - the timestamp the platform was last updated
 * @param {Number} datasetId - the parameter's dateset ID
 * @param {Number} parameterId - the parameter ID for the first parameter you want to display
 * @returns {string} Returns the research workspace URL with the proper start & end dates for the selected platform
 */
export const getWorkspaceUrl = (updatedDate, datasetId, parameterId) => {
  if (updatedDate !== undefined) {
    const sevenDaysEarlier = (updatedDate * 1000) - 604800000; // timestamp is in milliseconds
    const formattedStartDate = new Date(sevenDaysEarlier).toISOString().slice(0, 10);
    const formattedEndDate = new Date(updatedDate * 1000).toISOString().slice(0, 10);
    return `/workspace-beta?datasetIds=${datasetId}&endDate=${formattedEndDate}&parameterIds=${parameterId}&period=7%20days&startDate=${formattedStartDate}`;
  }
};

export const getLatestTimestamp = (dataset, obsData) => getLatestParameterDate(dataset, obsData);
