import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
} from 'antd';

import CustomModal from '../modal';
import { useAnnouncements } from './useAnnouncements';
import { ANNOUNCEMENT_LEVELS } from '../../utils/announcements';
import { useAcknowledgeAnnouncement } from './useAcknowledgeAnnouncement';
import HtmlText from '../html-text';

const { Title, Paragraph } = Typography;

export const CentralAnnouncement = ({ cognitoUser }) => {
  const { announcements, refetchAnnouncements } = useAnnouncements(cognitoUser, true, ANNOUNCEMENT_LEVELS.central);
  const acknowledgementMutation = useAcknowledgeAnnouncement();

  if (!announcements || announcements.length < 1) {
    return null;
  }

  // display the first announcement in the list
  const announcement = announcements[0];

  const acknowledgeAndClose = () => {
    acknowledgementMutation.mutate(announcement.announcement_id, {
      onSettled: () => refetchAnnouncements(),
    });
  };

  const timestamp = moment(announcement.begin_time).format('MMM Do, YYYY hh:mma');

  return (
    <CustomModal visible onOk={acknowledgeAndClose} onCancel={acknowledgeAndClose} className="announcementModal">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Title level={2} style={{ fontSize: 19 }}>{announcement.announcement_title}</Title>
        <Paragraph>
          <HtmlText string={announcement.announcement_text} />
        </Paragraph>
        <Paragraph style={{ fontSize: '12px' }}>
          {timestamp}
        </Paragraph>
      </div>
    </CustomModal>
  );
};

CentralAnnouncement.propTypes = {
  cognitoUser: PropTypes.object,
};

CentralAnnouncement.defaultProps = {
  cognitoUser: {},
};
