import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';

import MapQueryTypes from '../../../services/query-types/map';
import { getObsDataV2ById, parameterConfigurations } from '../../../services/dataset.service';
import { brandingConfig } from '../../../config/index';
import './OmicsDatasetSummary.scss';
import { parameterGroups } from '../../../config/parameterGroups';
// import Loading from '../../loading';

const OMICS_ABUNDANCE_NEGLIGIBLE_THRESHOLD = 0.0001;
const OMICS_ABUNDANCE_OTHER_THRESHOLD = 1;
const OMICS_ABUNDANCE_DISPLAY_THRESHOLD = 0.01;

const OmicsDatasetSummary = (props) => {
  const {
    info, // Do what you do to this here in DatasetDetail to selectedPlatform
    filters,
    parameters,
  } = props;
  const { properties } = info;

  const [chartData, setChartData] = useState([]);
  const [chartTime, setChartTime] = useState('');
  const [chartLabels, setChartLabels] = useState([]);
  const [startDate, setStartDate] = useState(filters.startDate.format('YYYY-MM-DD'));

  useEffect(() => {
    if (properties.observations) {
      setStartDate(moment(properties.observations[0].obs_timestamp).format('YYYY-MM-DD'));
    }
  }, [properties?.observations]);

  const obsDatasetId = properties.obs_dataset_id;

  const {
    data: observationalV2DataResult,
    isLoading: observationalV2DataIsLoading,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_V2_DATA_OMICS, startDate, obsDatasetId],
    () => getObsDataV2ById(startDate, obsDatasetId), {
      refetchOnWindowFocus: false,
      enabled: (!!startDate && !!obsDatasetId),
    },
  );

  const {
    data: parameterConfigurationsResult,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const chartOptions = {
    legend: {
      height: '100%',
      floating: true,
    },
    labels: chartLabels,
  };

  const validateOmicsData = (value, parameter) => {
    const omicsGroup = parameterGroups.find((g) => g.name === 'omicsOrganismAbundances');

    if (omicsGroup.standard_names.includes(parameter.standard_name) && value > OMICS_ABUNDANCE_NEGLIGIBLE_THRESHOLD) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (observationalV2DataResult && parameters && parameterConfigurationsResult) {
      const values = observationalV2DataResult?.parameter_obs.values;
      const times = observationalV2DataResult?.timestamps;
      const latestIndex = times.length - 1;
      const data = [];
      const otherData = [];
      const dataLabels = [];

      Object.keys(observationalV2DataResult?.parameter_obs.values).map((parameterId) => Number(parameterId)).forEach((parameterId) => {
        // Assume last obs is latest
        const obsValue = values[parameterId][latestIndex];
        const parameter = parameters.find((p) => p.parameter_id === parameterId);

        if (parameter && validateOmicsData(obsValue, parameter)) {
          const parameterConfig = parameterConfigurationsResult?.find((p) => p.standard_name === parameter.standard_name);
          const displayName = parameterConfig.display_name.en;
          const isCurrentFilter = filters.filter ? displayName.includes(filters.filter) : false;

          if (obsValue < OMICS_ABUNDANCE_OTHER_THRESHOLD && !isCurrentFilter) {
            otherData.push(obsValue);
          } else {
            data.push(obsValue);

            let label = obsValue < OMICS_ABUNDANCE_DISPLAY_THRESHOLD ? `${displayName} <0.01%` : `${displayName} ${obsValue.toFixed(2)}%`;

            if (isCurrentFilter) {
              label = `<strong>${label}</strong>`;
            }

            if (parameterConfig) {
              dataLabels.push(label);
            }
          }
        }
      });

      if (otherData.length > 0) {
        const otherSum = otherData.reduce((a, b) => a + b, 0);
        data.push(otherSum);
        const otherLabel = otherSum < OMICS_ABUNDANCE_DISPLAY_THRESHOLD ? 'Other <0.01%' : `Other ${otherSum.toFixed(2)}%`;
        dataLabels.push(otherLabel);
      }

      setChartData(data);
      setChartLabels(dataLabels);

      // Time formatting in JavaScript, from a one-shot
      // standpoint, is very limited. So to get it to design,
      // we need to get the pieces from the Date object
      // Formatter cite: https://stackoverflow.com/questions/1643320/get-month-name-from-date/18648314#18648314
      const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
      if (latestIndex >= 0) {
        let obsTime = times[latestIndex];
        obsTime = new Date(parseInt(`${obsTime.toString()}000`, 10));
        const obsMonth = formatter.format(obsTime);
        const obsDate = obsTime.getDate();
        const obsYear = obsTime.getFullYear();

        setChartTime(`${obsMonth} ${obsDate}, ${obsYear}`);
      } else {
        setChartTime('');
      }
    }
  }, [observationalV2DataResult, parameters, parameterConfigurationsResult]);

  return (
    <div className="omics-dataset-summary" style={{ width: '100%', height: '100%' }}>
      <div className="tooltip-header">
        <span className="h3">
          <div style={{ width: '400px' }}>
            {properties.platform_name}
          </div>
          <div
            className="caption-w"
            style={{ color: brandingConfig.colors.greyLight }}
          >
            <div>
              Lat:
              &nbsp;
              {info.geometry?.coordinates[1].toFixed(5)}
              &nbsp;&nbsp;
              Lon:
              &nbsp;
              {info.geometry?.coordinates[0].toFixed(5)}
            </div>
            {chartTime.length > 0 && (
              <div className="sample-date">
                <ClockCircleOutlined />
                &nbsp;
                Most recent sample
                &nbsp;
                <span>
                  (
                  {chartTime}
                  )
                </span>
              </div>
            )}
          </div>
        </span>
      </div>
      {/* This loading component seems to be causing issues with the tooltip persisting... */}
      {/* {observationalV2DataIsLoading && (
        <div style={{
          position: 'relative',
        }}
        >
          <Loading />
        </div>
      )} */}
      {observationalV2DataResult && !observationalV2DataIsLoading && (
        <div className="omics-chart-wrapper">
          {chartData.length > 0 && (
            <Chart options={chartOptions} series={chartData} type="donut" height="100%" width={400} />
          )}
        </div>
      )}
    </div>
  );
};

OmicsDatasetSummary.propTypes = {
  info: PropTypes.object,
  filters: PropTypes.object,
  parameters: PropTypes.array,
};

OmicsDatasetSummary.defaultProps = {
  info: {},
  filters: {},
  parameters: [],
};

export default memo(OmicsDatasetSummary);
