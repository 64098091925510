import React, {
  memo,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Button, Checkbox, Table } from 'antd';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import lodash from 'lodash';
import ReactGA from 'react-ga4';
import {
  HeartFilled,
  HeartOutlined,
} from '@ant-design/icons';
import { DataConsoleEvent } from '../../constant/google-analytics/constant';
import TileView from './components/TileView';
import { columns, columnsMobile } from './config';
import {
  getDatasetSummaries,
  getObsLatestData,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../services/dataset.service';
import MapQueryTypes from '../../services/query-types/map';
import { getUserFavoritePlatforms, createUserFavorite, deleteUserFavorite } from '../../services/profile.service';
import ProfileQuerytTypes from '../../services/query-types/profile';
import UserContext from '../../contexts/UserContext';
import './styles.scss';
import PlatformsFilter from './components/PlatformsFilter';
import { getLatestParameterDate, getParameters, platformGroupings } from '../../utils';
import CompareModal from './components/CompareModal';
import { statuses } from '../dashboard/constant';
import appConfig, { brandingConfig } from '../../config/index';
import MarkerStatus from '../../components/icons/MarkerStatus';
import Loading from '../../components/loading';
import NoFavorites from './components/NoFavorites';

const formatType = (str) => {
  if (!str) {
    return;
  }

  let i;
  const frags = str.split('_');

  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }

  return frags.join(' ');
};

const DataConsole = () => {
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const [comparePlatforms, setComparePlatforms] = useState([]);
  const [datasetData, setDatasetData] = useState([]);
  const [listView, setListView] = useState(true);
  const [desktopListView, setDesktopListView] = useState(!isMobile);
  const [searchQuery, setSearchQuery] = useState();
  const [selectedLake, setSelectedLake] = useState([]);
  const [selectedPlatformTypes, setSelectedPlatformTypes] = useState(['buoy', 'tower']);
  const [selectedStatus, setSelectedStatus] = useState([statuses[0].title]);
  const [sortOrder, setSortOrder] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { cognitoUser, unitPreferences } = useContext(UserContext);
  const [currentTab, setCurrentTab] = useState('favorite-datasets');
  const [favoriteDatasets, setFavoriteDatasets] = useState([]);
  const [nestDatasets, setNestDatasets] = useState([]);
  const [seagullDatasets, setSeagullDatasets] = useState([]);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
    if (windowWidth < 1360) {
      setListView(true);
    } else if (windowWidth >= 1360 && desktopListView) {
      setListView(true);
    }
  };
  useEffect(() => {
    // Attach event on window which will track window size changes and store the width in state
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [windowWidth]);

  const {
    data: obsDatasetSummariesResult,
    isLoading: obsDatasetSummariesIsLoading,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  const { data: observationalDataResult } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  const { data: parameterConfigurationsResult } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, {
    refetchOnWindowFocus: false,
  });

  const {
    data: registeredParametersResult,
    isLoading: registeredParametersIsLoading,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_DATACONSOLE, getRegisteredParameters, { refetchOnWindowFocus: false });

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
      enabled: !!cognitoUser?.username,
    },
  );

  const deleteFavoritePlatformMutation = useMutation((data) => deleteUserFavorite(data));
  const handleDeleteFavoritePlatform = (favId) => {
    deleteFavoritePlatformMutation.mutate(
      {
        id: favId,
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const addFavoritePlatformMutation = useMutation((id) => createUserFavorite(id));
  const handleAddFavoritePlatform = (id) => {
    addFavoritePlatformMutation.mutate(
      {
        obs_dataset_id: id,
        nickname: '',
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const {
    setAuthDrawerOpen,
  } = useContext(UserContext);

  const onClickSignIn = () => {
    setAuthDrawerOpen(true);
  };

  const handleFavoriteClick = (id, isFavorite) => {
    if (!cognitoUser) {
      onClickSignIn();
    }
    if (cognitoUser) {
      if (isFavorite) {
        handleDeleteFavoritePlatform(id);
      } else {
        handleAddFavoritePlatform(id);
      }
    }
  };

  const getFavoriteButton = (platform) => {
    let icon = (
      <HeartOutlined style={{ fontSize: 13, marginLeft: -1 }} />
    );
    let isFavorite = false;
    let trackingId = platform.obs_dataset_id;

    if (favoritePlatformData?.length > 0) {
      const favoriteData = favoritePlatformData.filter((fp) => fp.obs_dataset_id === platform.obs_dataset_id);
      if (favoriteData.length > 0) {
        icon = (
          <HeartFilled style={{ fontSize: 13, marginLeft: -1 }} />
        );
        isFavorite = true;
        trackingId = favoriteData[0].obs_dataset_favorite_id;
      }
    }

    return (
      <Button
        icon={icon}
        onClick={() => handleFavoriteClick(trackingId, isFavorite)}
        shape="circle"
        size="small"
        style={{
          border: 'none', backgroundColor: brandingConfig.colors.accent1, color: '#fff', marginLeft: listView ? 20 : 2,
        }}
      />
    );
  };

  const handleCompare = (e, platform) => {
    const { checked } = e.target;

    if (checked && comparePlatforms.length < 3) {
      setComparePlatforms((prevPlatforms) => [...prevPlatforms, platform]);
    } else if (!checked) {
      const newPlatforms = [...comparePlatforms];
      const platformIdx = newPlatforms.findIndex((o) => o.obs_dataset_id === platform.obs_dataset_id);
      if (platformIdx > -1) {
        newPlatforms.splice(platformIdx, 1);

        setComparePlatforms(newPlatforms);
      }
    }
  };

  const handleCompareRemove = (platform) => {
    const newPlatforms = [...comparePlatforms];
    const platformIdx = newPlatforms.findIndex((o) => o.obs_dataset_id === platform.obs_dataset_id);
    if (platformIdx > -1) {
      newPlatforms.splice(platformIdx, 1);

      setComparePlatforms(newPlatforms);
    }
  };

  const handleCompareReset = () => setComparePlatforms([]);

  useEffect(() => {
    if (
      obsDatasetSummariesResult
      && obsDatasetSummariesResult?.code !== 500
      && observationalDataResult
      && parameterConfigurationsResult
      && registeredParametersResult
    ) {
      const tableData = [];
      let obsSummariesFiltered = obsDatasetSummariesResult;
      obsSummariesFiltered = obsSummariesFiltered.filter((osf) => osf.obs_dataset_platform_assignment?.platform.external_id_type !== 'omics_sample_site');
      for (let i = 0; i < obsSummariesFiltered.length; i++) {
        const latestDate = getLatestParameterDate(obsSummariesFiltered[i], observationalDataResult, true);
        obsSummariesFiltered[i].latestDate = latestDate;
        const timeSince = moment().diff(moment(latestDate), 'hours');
        const externalIdType = obsSummariesFiltered[i]?.obs_dataset_platform_assignment?.platform.external_id_type;

        // TODO: refactor this platform status logic to be part of getObsLatestData rather than repeating it in several components
        // TODO: refactor platform statuses to be a constant/enum rather than repeated strings
        // exclude ESP sites from old data cutoff
        if (timeSince > appConfig.oldDataCutoffInHours && externalIdType !== 'esp_site') {
          if (obsSummariesFiltered[i].obs_dataset_platform_assignment.platform?.platform_event) {
            // only set ACTIVE platforms to unavailable if there is no recent data
            if (obsSummariesFiltered[i].obs_dataset_platform_assignment.platform.platform_event.event === 'activated') {
              obsSummariesFiltered[i].obs_dataset_platform_assignment.platform.platform_event.event = 'unavailable';
              obsSummariesFiltered[i].obs_dataset_platform_assignment.platform.platform_event.collection_status = 'unavailable';
            }
          }
        }
      }

      if (searchQuery) {
        obsSummariesFiltered = obsSummariesFiltered.filter((o) => o.org_platform_id.toLowerCase() === searchQuery
          || o.platform_name.toLowerCase().includes(searchQuery.toLowerCase()));
      }

      if (selectedLake && selectedLake.length > 0) {
        const selectedLakeArr = selectedLake.map((lake) => `lake-${lake.toLowerCase()}`);
        obsSummariesFiltered = obsSummariesFiltered.filter(
          (o) => selectedLakeArr.includes(o.deployment_site.body_of_water),
        );
      }

      if (selectedPlatformTypes && selectedPlatformTypes.length > 0) {
        obsSummariesFiltered = obsSummariesFiltered.filter((o) => (
          selectedPlatformTypes.some((item) => {
            const platformType = o.obs_dataset_platform_assignment?.platform?.platform_type;
            return (
              platformGroupings[item].includes(platformType)
            );
          })
        ));
      }

      if (selectedStatus && selectedStatus.length > 0) {
        const selectedStatusArr = selectedStatus.map((status) => status.split(' ').join('_').toLowerCase());
        obsSummariesFiltered = obsSummariesFiltered.filter(
          (o) => selectedStatusArr.includes(o.obs_dataset_platform_assignment?.platform.platform_event?.event),
        );
      }

      if (sortOrder) {
        const obsSummariesFilteredOrig = obsSummariesFiltered;
        const { type: orderBy, order: orderByDir } = sortOrder;
        const orderByName = orderBy === 'id' ? 'org_platform_id' : 'platform_name';

        obsSummariesFiltered = lodash.isEmpty(sortOrder)
          ? obsSummariesFilteredOrig
          : lodash.orderBy(obsSummariesFiltered, [orderByName], [orderByDir]);
      }

      obsSummariesFiltered.forEach((datasetRow) => {
        const platformDataset = datasetRow.obs_dataset_platform_assignment;

        const parameterData = getParameters({
          dataset: datasetRow,
          obsData: observationalDataResult,
          parameterConfigurations: parameterConfigurationsResult,
          registeredParameters: registeredParametersResult,
          unitPreferences,
        });

        let isFavorite = false;
        if (favoritePlatformData) {
          if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
            console.log('WARNING: login signature failed');
          } else {
            const findFavorite = favoritePlatformData.filter((fpd) => fpd.obs_dataset_id === platformDataset.obs_dataset_id);
            isFavorite = findFavorite.length > 0;
          }
        }

        const latestDate = getLatestParameterDate(datasetRow, observationalDataResult, true);
        const timeAgo = latestDate ? moment(latestDate).fromNow() : '';

        if (platformDataset.platform.platform_event && platformDataset.platform.platform_event.event === 'activated' && !datasetRow.latestDate) {
          platformDataset.platform.platform_event.event = 'unavailable';
        }

        const properties = platformDataset.platform.platform_event;
        properties.organization_id = datasetRow.organization_id;

        const statusEl = platformDataset.platform.platform_event ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <MarkerStatus
              iconProps={{
                style: {
                  whiteSpace: listView ? 'nowrap' : 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: 0,
                },
                height: platformDataset.platform.organization_id === brandingConfig.filters.organizationId ? '14px' : '20px',
                width: platformDataset.platform.organization_id === brandingConfig.filters.organizationId ? '14px' : '20px',
              }}
              isFavorite={isFavorite}
              properties={properties}
            />
          </span>
        ) : (
          <span />
        );

        const datasetDataRow = {
          key: datasetRow.obs_dataset_id,
          platform_id: listView && isMobile ? (
            <Link
              style={{ color: '#fff' }}
              to={`/data-console/${datasetRow.obs_dataset_id}`}
            >
              {datasetRow.org_platform_id}
            </Link>
          ) : (
            <span>
              {datasetRow.org_platform_id}
            </span>
          ),
          platform_name: listView ? (
            <Link
              style={{ color: '#fff' }}
              className="h3"
              to={`/data-console/${datasetRow.obs_dataset_id}`}
            >
              {datasetRow.platform_name}
            </Link>
          ) : (
            <span
              style={{ color: '#fff' }}
              className="h3"
            >
              {datasetRow.platform_name}
            </span>
          ),
          type: formatType(platformDataset.platform.platform_type),
          status: statusEl,
          updated: timeAgo,
          updated_raw: datasetRow.latestDate,
          parameters: parameterData,
          dataset: platformDataset,
          favorites: getFavoriteButton(datasetRow),
        };

        // Add dynamic data
        for (let i = 0; i < parameterData.length; i++) {
          const parameterItem = parameterData[i];
          datasetDataRow[
            parameterItem.standard_name
          ] = `${parameterItem.value}${parameterItem.unit}`;
        }

        datasetDataRow.compare = (
          <Checkbox
            disabled={comparePlatforms.length === 3 && !comparePlatforms.some((platform) => platform.obs_dataset_id === datasetRow.obs_dataset_id)}
            checked={comparePlatforms.some((platform) => platform.obs_dataset_id === datasetRow.obs_dataset_id)}
            onChange={(e) => handleCompare(e, datasetRow)}
            style={{ color: '#fff', marginLeft: '20px' }}
          >
            {!listView ? ' Compare' : ''}
          </Checkbox>
        );
        tableData.push(datasetDataRow);
      });

      setDatasetData(tableData);

      if (favoritePlatformData?.length > 0) {
        // set favorite datasets
        const favoriteDatasetIds = favoritePlatformData.map((f) => f.obs_dataset_id);
        const favoriteData = tableData.filter((platform) => favoriteDatasetIds.includes(platform.dataset.obs_dataset_id));
        setFavoriteDatasets(favoriteData);
      }

      // set datasets in nest
      const nestData = tableData.filter((d) => d.dataset.platform.organization_id === brandingConfig.filters.organizationId);
      setNestDatasets(nestData);

      // set datasets NOT in nest. If this is base Seagull (not a nest) this will be all datasets
      const seagullData = tableData.filter((d) => d.dataset.platform.organization_id !== brandingConfig.filters.organizationId);
      setSeagullDatasets(seagullData);
    }
  }, [
    columns,
    comparePlatforms,
    obsDatasetSummariesResult,
    observationalDataResult,
    parameterConfigurationsResult,
    registeredParametersResult,
    listView,
    searchQuery,
    selectedLake,
    selectedPlatformTypes,
    selectedStatus,
    sortOrder,
    favoritePlatformData,
    unitPreferences,
  ]);

  const handleLakeFilter = (lake, checked) => {
    let lakes = [...selectedLake];
    if (checked) {
      lakes.push(lake);
    } else {
      lakes = lakes.filter((stat) => stat !== lake);
    }
    setSelectedLake(lakes);
    ReactGA.event({ ...DataConsoleEvent.LakeFilterSelect, label: `${lake}` });
  };

  const handlePlatformChange = (platformType, checked) => {
    let platformTypes = [...selectedPlatformTypes];

    if (checked) {
      platformTypes.push(platformType);
    } else {
      platformTypes = platformTypes.filter((stat) => stat !== platformType);
    }
    setSelectedPlatformTypes(platformTypes);
    ReactGA.event({ ...DataConsoleEvent.PlatformTypeFilter, label: `${platformType}` });
  };

  const handleSearch = (e) => setSearchQuery(e.target.value);

  const handleStatusChange = (status, checked) => {
    let newStatuses = [...selectedStatus];
    if (checked) {
      newStatuses.push(status);
    } else {
      newStatuses = newStatuses.filter((stat) => stat !== status);
    }

    setSelectedStatus(newStatuses);
    ReactGA.event({ ...DataConsoleEvent.PlatformStatusFilter, label: `${status}` });
  };

  const handleSort = (value) => {
    const [type, order] = value.split('_');

    setSortOrder({
      type,
      order,
    });
  };

  const toggleView = () => {
    setListView(!listView);
    setDesktopListView(!desktopListView);
    ReactGA.event(DataConsoleEvent.ListViewToggled);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const getTableData = () => {
    if (currentTab === 'favorite-datasets') {
      return favoriteDatasets;
    }

    if (currentTab === 'nest-datasets') {
      return nestDatasets;
    }

    if (currentTab === 'seagull-datasets') {
      return seagullDatasets;
    }

    return datasetData;
  };

  return (
    <div className="data-console">
      {!isMobile
        && (
          <PlatformsFilter
            isMobile={isMobile}
            listView={listView}
            platformCount={seagullDatasets.length}
            selectedLakes={selectedLake}
            selectedPlatformtags={selectedPlatformTypes}
            selectedStatus={selectedStatus}
            currentTab={currentTab}
            favoritesCount={favoriteDatasets.length}
            handleToggleView={toggleView}
            handleLakeChange={handleLakeFilter}
            handlePlatformChange={handlePlatformChange}
            handleSearch={handleSearch}
            handleSort={handleSort}
            handleStatusChange={handleStatusChange}
            handleTabChange={handleTabChange}
            nestPlatformsCount={nestDatasets.length}
          />
        )}
      <div className="main">
        {isMobile
          && (
            <PlatformsFilter
              isMobile={isMobile}
              listView={listView}
              platformCount={seagullDatasets.length}
              selectedLakes={selectedLake}
              selectedPlatformtags={selectedPlatformTypes}
              selectedStatus={selectedStatus}
              currentTab={currentTab}
              favoritesCount={favoriteDatasets.length}
              handleToggleView={toggleView}
              handleLakeChange={handleLakeFilter}
              handlePlatformChange={handlePlatformChange}
              handleSearch={handleSearch}
              handleSort={handleSort}
              handleStatusChange={handleStatusChange}
              handleTabChange={handleTabChange}
            />
          )}
        <div className="data-list" data-testid="console-listview">
          {(obsDatasetSummariesIsLoading || registeredParametersIsLoading) ? (
            <Loading />
          ) : (
            <>
              <CompareModal platforms={comparePlatforms} onRemove={handleCompareRemove} onReset={handleCompareReset} />
              {listView ? (
                <div data-testid="console-listview">
                  <Table
                    columns={isMobile ? columnsMobile : columns}
                    dataSource={getTableData()}
                    size="small"
                    pagination={false}
                    className="full-width"
                    locale={{ emptyText: currentTab === 'favorite-datasets' ? <NoFavorites /> : null }}
                  />
                </div>
              ) : (
                <TileView dataSource={getTableData()} isMobile={isMobile} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DataConsole.propTypes = {};

DataConsole.defaultProps = {};

export default memo(DataConsole);
