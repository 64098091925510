const MapQueryTypes = {
  REST_OBSERVATIONAL_DATA: 'REST_OBSERVATIONAL_DATA', // Dashboard
  REST_OBSERVATIONAL_DATA_CONSOLE_DETAIL: 'REST_OBSERVATIONAL_DATA_CONSOLE_DETAIL',
  REST_OBSERVATIONAL_DATA_PARAMETER_DETAIL: 'REST_OBSERVATIONAL_DATA_PARAMETER_DETAIL',
  REST_OBSERVATIONAL_DATA_PARAMETER_DETAIL_OMICS: 'REST_OBSERVATIONAL_DATA_PARAMETER_DETAIL_OMICS',
  REST_OBSERVATIONAL_V2_DATA: 'REST_OBSERVATIONAL_V2_DATA',
  REST_OBSERVATIONAL_V2_DATA_OMICS: 'REST_OBSERVATIONAL_V2_DATA_OMICS',
  REST_OBSERVATIONAL_LATEST_DATA: 'REST_OBSERVATIONAL_LATEST_DATA',
  REST_OBSERVATIONAL_PLATFORMS: 'REST_OBSERVATIONAL_PLATFORMS',
  REST_OBSERVATIONAL_PLATFORMS_BY_PLATFORM: 'REST_OBSERVATIONAL_PLATFORMS_BY_PLATFORM',
  REST_OBSERVATIONAL_PLATFORMS_BY_ORG: 'REST_OBSERVATIONAL_PLATFORMS_BY_ORG',
  REST_OBS_DATASETS: 'REST_OBS_DATASETS',
  REST_OBS_DATASET_SUMMARIES: 'REST_OBS_DATASET_SUMMARIES', // Dashboard
  REST_OBS_DATASET_SUMMARIES_DATASET_DETAIL: 'REST_OBS_DATASET_SUMMARIES_DATASET_DETAIL',
  REST_OBS_DATASET_SUMMARIES_PARAMETER_DETAIL: 'REST_OBS_DATASET_SUMMARIES_PARAMETER_DETAIL',
  REST_OBS_DATASETS_LATEST: 'REST_OBS_DATASETS_LATEST',
  REST_ORGANIZATIONS: 'REST_ORGANIZATIONS',
  REST_USER_ORGANIZATIONS: 'REST_USER_ORGANIZATIONS',
  REST_PARAMETERS_REGISTERED: 'REST_PARAMETERS_REGISTERED',
  REST_PARAMETERS_REGISTERED_GROUPDETAIL: 'REST_PARAMETERS_REGISTERED_GROUPDETAIL',
  REST_PARAMETERS_REGISTERED_ALERTS: 'REST_PARAMETERS_REGISTERED_ALERTS',
  REST_PARAMETERS_REGISTERED_CREATEALERT: 'REST_PARAMETERS_REGISTERED_CREATEALERT',
  REST_PARAMETERS_REGISTERED_PARAMETERDETAIL: 'REST_PARAMETERS_REGISTERED_PARAMETERDETAIL',
  REST_PARAMETERS_REGISTERED_CONSOLEDETAIL: 'REST_PARAMETERS_REGISTERED_CONSOLEDETAIL',
  REST_PARAMETERS_REGISTERED_DATACONSOLE: 'REST_PARAMETERS_REGISTERED_DATACONSOLE',
  REST_PARAMETERS_REGISTERED_CONSOLECOMPARE: 'REST_PARAMETERS_REGISTERED_CONSOLECOMPARE',
  REST_PARAMETERS_REGISTERED_DASHBOARD: 'REST_PARAMETERS_REGISTERED_DASHBOARD',
  REST_PARAMETER_CONFIGURATIONS: 'REST_PARAMETER_CONFIGURATIONS',
  REST_PARAMETERS: 'REST_PARAMETERS',
  REST_SEARCH_DATA: 'REST_SEARCH_DATA',
  REST_PLATFORM_METADATA: 'REST_PLATFORM_METADATA',
  REST_PLATFORM_SPONSORS: 'REST_PLATFORM_SPONSORS',
  REST_PLATFORM_WEATHER: 'REST_PLATFORM_WEATHER',
  REST_ORGANIZATION_DATASETS: 'REST_ORGANIZATION_DATASETS',
  REST_OBSERVATIONAL_DATASET_TRAJECTORIES: 'REST_OBSERVATIONAL_DATASET_TRAJECTORIES',
};

export default MapQueryTypes;
