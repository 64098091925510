import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { preferredWindMagnitudeUnit } from '../../../../utils';
import { CloseIcon, WindSpeedIcon } from '../../../icons';

const WindMagnitude = (props) => {
  const {
    isActive,
    setIsActive,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const windRange = isMobile ? [16, 14, 12, 9, 6, 3, 0] : [13, 11, 9, 7, 5, 3, 0];

  return (
    <>
      <div className={`map-layer-card ${isActive ? 'active' : ''}`} onClick={() => { if (!isActive) { setIsActive(true); } }}>
        <div className="map-layer-title">
          <div className="map-layer-icons">
            <WindSpeedIcon />
            <CloseIcon className="close-button" onClick={() => setIsActive(false)} style={isActive ? {} : { display: 'none' }} />
          </div>
          <div>Wind</div>
          <div>Speed</div>
        </div>
        <div className={`map-layer-content ${isActive ? 'active' : ''}`}>
          <div className="map-layer-legend-container">
            <div className="legend wind-mag-legend">
              {windRange.map((windRange, index) => (
                <div className="temp-legend-item" key={index}>
                  <span className="temp-legend-item-text">
                    {preferredWindMagnitudeUnit(windRange)}
                    <div className="temp-legend-item-arrow" />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
WindMagnitude.defaultProps = {
  isActive: false,
  setIsActive: () => {},
};
WindMagnitude.propTypes = {
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
};

export default WindMagnitude;
