import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import moment from 'moment';

import { useMediaQuery } from 'react-responsive';
import Parameter from '../../../components/parameter';
import { getPreferredMeasurementUnit } from '../../../utils';
import
appConfig,
{ brandingConfig }
  from '../../../config';
import { parameterGroups } from '../../../config/parameterGroups';

const FeaturedParameters = (props) => {
  const {
    categorySelected,
    categoriesPresent,
    handleSetCategoryArray,
    parameters,
    onClick,
    isSamplingLocation,
    canAddToGraph,
    parameterOnGraph,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const filteredParameters = parameters.filter((parameter) => {
    const timeSince = moment().diff(moment(parameter.date), 'hours');
    if ((Number.isNaN(parameter.value) || timeSince >= appConfig.oldDataCutoffInHours)) {
      return !!isSamplingLocation;
    }
    if (!categorySelected) {
      return parameter;
    }
    const parameterGroup = parameterGroups.find((group) => group.name === categorySelected);
    if (!parameterGroup) {
      return parameter;
    }
    return parameterGroup.parameters.includes(parameter.name);
  });

  useEffect(() => {
    filteredParameters.forEach((parameter) => {
      const parameterGroup = parameterGroups.find((group) => (group.parameters.includes(parameter.name)));
      if (parameterGroup?.name && !categoriesPresent.includes(parameterGroup.name) && !isSamplingLocation) {
        handleSetCategoryArray([...categoriesPresent, parameterGroup.name]);
      }
    });
  }, [filteredParameters]);

  const getParameterWidgets = () => filteredParameters.map((parameter, index) => {
    const previousParameter = filteredParameters[index - 1];

    return (
      <React.Fragment key={index}>
        {(!previousParameter || (parameter.depth !== previousParameter.depth)) && !isSamplingLocation
            && (
              <Col span={24} key={`depth-group-${parameter.depthGroup}`}>
                <div style={{
                  display: 'flex', alignItems: 'center', color: '#fff', fontSize: 12,
                }}
                >
                  {!parameter.depth || parameter.depth === 0
                    ? <span style={{ marginRight: 15 }}>SURFACE</span>
                    : (
                      <span style={{ marginRight: 15, whiteSpace: 'nowrap' }}>
                        {getPreferredMeasurementUnit(parameter.depth)}
                        {' '}
                        DEPTH
                      </span>
                    )}
                  <div style={{ backgroundColor: brandingConfig.colors.grey, height: 1, width: '100% ' }} />
                </div>
              </Col>
            )}
        <Col
          key={index}
          span={isMobile ? 24 : 12}
        >
          <Card
            headStyle={{ border: 'none', paddingBottom: 0 }}
            bodyStyle={{
              padding: 20, position: 'relative', bottom: '0px',
            }}
            className="featured-parameter"
          >
            <div
              style={{
                // cursor: 'pointer',
              }}
            >
              <Parameter
                date={parameter.date}
                name={parameter.name}
                nameEllipsis
                value={parameter.value}
                unit={parameter.unit}
                dataVerifiedVisible={false}
                metricVisible={false}
                isDiveDeeper
                isMobile={isMobile}
                pinned={parameter.pinned}
                isSnapshot
                onClick={() => onClick(parameter)}
              />
              {canAddToGraph && (
                <div className="add-to-graph">
                  {parameterOnGraph === parameter.name ? 'Remove from ' : 'Add to ' }
                  graph
                </div>
              )}
            </div>
          </Card>
        </Col>
      </React.Fragment>
    );
  });

  const parameterWidgets = getParameterWidgets(parameters);

  return (
    <Row gutter={20}>
      {parameterWidgets}
    </Row>
  );
};

FeaturedParameters.propTypes = {
  parameters: PropTypes.array,
  categorySelected: PropTypes.string,
  onClick: PropTypes.func,
  categoriesPresent: PropTypes.array,
  handleSetCategoryArray: PropTypes.func,
  isSamplingLocation: PropTypes.bool,
  canAddToGraph: PropTypes.bool,
  parameterOnGraph: PropTypes.string,
};

FeaturedParameters.defaultProps = {
  parameters: [],
  onClick: () => {},
  categorySelected: [],
  categoriesPresent: [],
  handleSetCategoryArray: () => { },
  isSamplingLocation: false,
  canAddToGraph: false,
  parameterOnGraph: '',
};

export default FeaturedParameters;
