import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import {
  CaretUpFilled,
  ClockCircleOutlined,
  SafetyCertificateFilled,
} from '@ant-design/icons';
import moment from 'moment';
import DegreesToCardinal from './DegreesToCardinal';
import { brandingConfig } from '../../config';
import './styles.scss';
import ParameterColors from './parameterColors';
import { getParameterGroupIcon } from '../../utils';

const Parameter = (props) => {
  const {
    icon,
    date,
    minimized,
    name,
    nameEllipsis,
    value,
    unit,
    style,
    metricVisible,
    dataVerifiedVisible,
    onClick,
    isMobile,
    isSnapshot,
    pinned,
    standardName,
  } = props;

  const IconComponent = () => (
    icon ? (
      <span>
        {icon}
        &nbsp;
      </span>
    ) : ''
  );

  const formattedValue = DegreesToCardinal(value, unit);

  return (
    minimized
      ? (
        <div>
          <div className="h2">
            {formattedValue}
          </div>
          <div>{name}</div>
        </div>
      )
      : (
        <>
          <ParameterColors
            name={name}
            isSnapshot={isSnapshot}
            pinned={pinned}
            onClick={onClick}
            standardName={standardName}
          />
          <div
            style={{
              ...style, display: 'flex', flexDirection: 'column', justifyContent: 'center',
            }}
            onClick={() => onClick()}
            className="parameter"
          >
            <div style={{ display: 'flex' }}>
              <span className="h1">
                {formattedValue}
              </span>
              {metricVisible
                ? (
                  <Statistic
                    title=""
                    value={2.1}
                    precision={2}
                    valueStyle={{
                      color: brandingConfig.colors.success, fontSize: 12, marginTop: '20%', marginLeft: 10,
                    }}
                    prefix={<CaretUpFilled />}
                    suffix="%"
                  />
                )
                : ''}
            </div>
            <div style={isMobile ? { fontWeight: 400, textDecoration: 'underline' } : { fontWeight: 500 }} className={nameEllipsis ? 'ellipsis' : ''}>
              <IconComponent />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getParameterGroupIcon(name)}
                <span style={nameEllipsis ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',

                } : {}}
                >
                  <span style={{ fontSize: 16 }}>
                    {name}
                  </span>
                </span>
              </div>
            </div>
            <div className="caption" style={{ color: brandingConfig.colors.greyLight }}>
              <ClockCircleOutlined />
              &nbsp;
              {moment(date).fromNow()}
            </div>
            {dataVerifiedVisible
              ? (
                <div className="caption-w">
                  <SafetyCertificateFilled />
                  &nbsp;
                  Data Verified
                </div>
              ) : ''}
          </div>
        </>
      )
  );
};

Parameter.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  nameEllipsis: PropTypes.bool,
  value: PropTypes.number,
  unit: PropTypes.string,
  date: PropTypes.number,
  minimized: PropTypes.bool,
  style: PropTypes.object,
  metricVisible: PropTypes.bool,
  dataVerifiedVisible: PropTypes.bool,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  isSnapshot: PropTypes.bool,
  pinned: PropTypes.bool,
  standardName: PropTypes.string,
};

Parameter.defaultProps = {
  icon: null,
  name: '',
  nameEllipsis: false,
  value: '',
  unit: '',
  date: 0,
  minimized: false,
  style: {},
  metricVisible: true,
  dataVerifiedVisible: false,
  onClick: () => { },
  isMobile: false,
  isSnapshot: false,
  pinned: false,
  standardName: '',
};

export default memo(Parameter);
