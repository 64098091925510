import React from 'react';

import { VirusIcon } from '../components/icons';

// TOOO: named exports
import AirQualityIcon from '../components/icons/AirQualityIcon';
import CurrentsIcon from '../components/icons/CurrentsIcon';
import DiagnosticsIcon from '../components/icons/DiagnosticsIcon';
import WaterPropertyIcon from '../components/icons/WaterPropertyIcon';
import WaterQualityIcon from '../components/icons/WaterQualityIcon';
import WaterTempIcon from '../components/icons/WaterTempIcon';
import WavesIcon from '../components/icons/WavesIcon';
import WindIcon from '../components/icons/WindIcon';
import { featureFlag } from '../constant';

// Without unSelectedColor key/value pair, white category button will cover text upon selection
const parameterGroups = [
  {
    name: 'diagnostics',
    label: 'Diagnostics',
    unSelectedColor: 'white',
    selectedColor: 'black',
    icon: <DiagnosticsIcon style={{
      height: '14px', width: '18px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Battery voltage', 'Specific Conductivity', 'Incident solar radiation (downwelling, shortwave)', 'Chromophoric dissolved organic matter (cdom)',
    ],
  },
  {
    name: 'waterQuality',
    label: 'Water Quality',
    unSelectedColor: '#1db6c4',
    selectedColor: '#1db6c4',
    icon: <WaterQualityIcon style={{
      height: '13px', width: '18px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Ammonia',
      'Ammonium',
      'Chlorophyll concentration',
      'Chlorophyll fluorescence',
      'Chromophoric dissolved organic matter (CDOM)',
      'Dissolved oxygen',
      'Dissolved oxygen saturation',
      'Fluorescent dissolved organic matter (fdom)',
      'Microcystin concentration',
      'Microcystin is estimate',
      'Microcystin LLOD',
      'Microcystin LLOQ',
      'Nitrate',
      'Oxidation reduction potential',
      'pH',
      'Turbidity',
      'Phycocyanin concentration',
      'Phycocyanin fluorescence',
      'Total Nitrogen',
      'Total phosphorus',
      'Total suspended solids',
      'Phycocyanin cell concentration',
    ],
  },
  {
    name: 'waterTemp',
    label: 'Water Temperature',
    unSelectedColor: '#158c96',
    selectedColor: '#158c96',
    icon: <WaterTempIcon style={{
      height: '20px', width: '20px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Surface water temperature',
      'Water temp at depth',
    ],
  },
  {
    name: 'waterProperty',
    label: 'Water Property',
    unSelectedColor: '#1db6c4',
    selectedColor: '#1db6c4',
    icon: <WaterPropertyIcon style={{
      height: '20px', width: '17px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Conductivity',
      'Photosyntheticallly available radiation in water',
      'Salinity',
      'Specific conductivity',
      'Water pressure',
      'Water pressure due to water',
      'Water partial pressure of CO2',
      'Sea water density',
    ],
  },
  {
    name: 'wind',
    label: 'Wind',
    unSelectedColor: '#7cdf09',
    selectedColor: '#7cdf09',
    icon: <WindIcon style={{
      height: '20px', width: '25px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Wind chill',
      'Wind direction (from)',
      'Wind Gust Speed',
      'Wind speed',
    ],
  },
  {
    name: 'waves',
    label: 'Waves',
    unSelectedColor: '#4cb1f7',
    selectedColor: '#4cb1f7',
    icon: <WavesIcon style={{
      height: '20px', width: '25px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Dominant wave direction (from)',
      'Dominant wave directional spread',
      'Dominant wave period',
      'Maximum wave height',
      'Maximum wave period',
      'Mean wave directional spread',
      'Mean wave height of highest tenth',
      'Mean wave period',
      'Significant wave height',
      'Significant wave period',
      'Wave direction (from)',
    ],
  },
  {
    name: 'currents',
    label: 'Currents',
    unSelectedColor: '#2880bf',
    selectedColor: '#2880bf',
    icon: <CurrentsIcon style={{
      height: '18px', width: '22px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Current Direction (from)',
      'Current Speed',
      'Eastward Current at Depth ',
      'Northward Current at Depth',
      'Upward velocity',
      'River discharge',
    ],
  },
  {
    name: 'airQuality',
    label: 'Air Quality',
    unSelectedColor: '#7cdf09',
    selectedColor: '#7cdf09',
    icon: <AirQualityIcon style={{
      height: '22px', width: '22px', marginRight: 6, overflow: 'visible',
    }}
    />,
    parameters: [
      'Air pressure',
      'Air pressure at mean sea level',
      'Air temperature',
      'Albedo',
      'Atmospheric visibility',
      'Dew point temperature',
      'Surface downwelling shortwave radiation flux',
      'Net downward longwave radiation flux',
      'Net downward shortwavewave radiation flux',
      'Photosynthetically available radiation in air',
      'Precipitation amount',
      'Precipitation rate',
      'Relative humidity',
      'Surface downwelling longwavewave radiation flux',
      'Surface upwelling longwavewave radiation flux',
      'Surface upwelling shortwavewave radiation flux',
      'Total net downward radiation flux',
      'Pressure tendency',
    ],
  },
];

if (featureFlag.omics) {
  parameterGroups.push(
    {
      name: 'omicsOrganismAbundances',
      label: 'Organism Abundance',
      unSelectedColor: '#686fed',
      selectedColor: '#7cdf09',
      icon: <VirusIcon style={{
        height: '22px', width: '22px', marginRight: 6, overflow: 'visible',
      }}
      />,
      parameters: [
        'Anabaena abundance',
        'Aphanizomenon abundance',
        'Cuspidothrix abundance',
        'Cyanobium abundance',
        'Cylindrospermopsis abundance',
        'Diatoms abundance',
        'Didymosphenia abundance',
        'Dolichospermum abundance',
        'Dreissenid mussel abundance',
        'Lyngbya abundance',
        'Microcystis abundance',
        'Oscillatoria abundance',
        'Planktothrix abundance',
        'Pseudanabaena abundance',
        'Raphidiopsis abundance',
      ],
      standard_names: [
        'anabaena_abundance',
        'aphanizomenon_abundance',
        'cuspidothrix_abundance',
        'cyanobium_abundance',
        'cylindrospermopsis_abundance',
        'diatoms_abundance',
        'didymosphenia_abundance',
        'dolichospermum_abundance',
        'dreissenid_mussels_abundance',
        'lyngbya_abundance',
        'microcystis_abundance',
        'oscillatoria_abundance',
        'planktothrix_abundance',
        'pseudanabaena_abundance',
        'raphidiopsis_abundance',
      ],
      isVisibleByDefault: false,
    },
    {
      name: 'omicsOrganismCounts',
      label: 'Organism Count',
      unSelectedColor: '#686fed',
      selectedColor: '#7cdf09',
      icon: <VirusIcon style={{
        height: '22px', width: '22px', marginRight: 6, overflow: 'visible',
      }}
      />,
      parameters: [
        'Anabaena cell count',
        'Aphanizomenon count',
        'Cuspidothrix count',
        'Cyanobium count',
        'Cylindrospermopsis cell count',
        'Diatoms count',
        'Didymosphenia count',
        'Dolichospermum count',
        'Dreissenid mussel count',
        'Lyngbya count',
        'Microcystis cell count',
        'Oscillatoria count',
        'Planktothrix cell count',
        'Pseudanabaena count',
        'Raphidiopsis cell count',
      ],
      standard_names: [
        'anabaena_count',
        'aphanizomenon_count',
        'cuspidothrix_count',
        'cyanobium_count',
        'cylindrospermopsis_count',
        'diatoms_count',
        'didymosphenia_count',
        'dolichospermum_count',
        'dreissenid_mussels_count',
        'lyngbya_count',
        'microcystis_count',
        'oscillatoria_count',
        'planktothrix_count',
        'pseudanabaena_count',
        'raphidiopsis_count',
      ],
      isVisibleByDefault: false,
    },
  );
}

if (featureFlag.omicsToxins) {
  parameterGroups.push({
    name: 'omicsToxins',
    label: 'Toxin',
    unSelectedColor: '#eecc11',
    selectedColor: '#eecc11',
    parameters: [
      'All',
      'Lorem ipsum',
      'dolor sit',
      'amet, consectet',
      'adipiscing elit',
      'Etiam facilisis',
      'nisl quis',
      'odio imperdiet',
      'fringilla',
      'In nec',
      'velit accumsan',
    ],
    isVisibleByDefault: false,
  });
}

export { parameterGroups };
