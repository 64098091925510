import React, {
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { ClockCircleOutlined } from '@ant-design/icons';
import DegreesToCardinal from '../../parameter/DegreesToCardinal';
import { getUserFavoritePlatforms } from '../../../services/profile.service';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import UserContext from '../../../contexts/UserContext';
import appConfig, { brandingConfig } from '../../../config/index';
import MarkerStatus from '../../icons/MarkerStatus';
import { getLatestTimestamp } from '../../../utils';

const DatasetSummary = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { info, parameters, obsData } = props;
  const { properties } = info;
  const { cognitoUser } = useContext(UserContext);
  const [isFavorite, setIsFavorite] = useState(false);

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  useEffect(() => {
    if (favoritePlatformData) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
      } else {
        const foundFavorite = favoritePlatformData.filter((fpd) => fpd.obs_dataset_id === properties.obs_dataset_id);
        if (foundFavorite.length > 0) {
          setIsFavorite(true);
        }
      }
    }
  }, [favoritePlatformData]);

  const getStatus = (status) => {
    switch (status) {
      case 'activated':
        return 'active';
      case 'deactivated':
        return 'inactive';
      default:
        return status;
    }
  };

  const desiredOrder = ['Air temperature', 'Surface water temperature', 'Wind speed', 'Wind direction (from)', 'Air pressure at mean sea level', 'Significant wave height', 'Wave direction (from)', 'Dominant wave period', 'Chlorophyll fluorescence', 'Turbidity', 'pH', 'Phycocyanin fluorescence'];
  const getPriority = (name) => (desiredOrder.indexOf(name) >= 0 ? desiredOrder.indexOf(name) : 100);
  const platformEvent = properties.platform_event
    ? `This platform is ${getStatus(properties.platform_event)} and not ${properties.last_updated_parameter_in_hours > appConfig.oldDataCutoffInHours ? 'sending us' : 'reporting'} data` : 'There is no reporting data';

  const externalIdType = properties?.platform?.external_id_type;

  return (
    <div style={{ width: 300 }}>
      <div className="d-flex">
        <span className="h3" style={{ marginTop: 5, marginBottom: 5 }}>
          <div>{properties.org_platform_id}</div>
          {properties.platform_name}
          <div
            className="caption-w"
            style={{ color: brandingConfig.colors.greyLight }}
          >
            <ClockCircleOutlined />
            &nbsp;
            {getLatestTimestamp(properties, obsData)}
          </div>
        </span>
        <span>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <MarkerStatus
              properties={properties}
              isFavorite={isFavorite}
              iconProps={{
                height: '19px',
                width: '19px',
              }}
            />
          </span>
        </span>
      </div>

      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        {parameters.length > 0
          && properties.last_updated_parameter_in_hours <= appConfig.oldDataCutoffInHours
          && externalIdType !== 'esp_site'
          ? parameters.sort((a, b) => (getPriority(a.name) - getPriority(b.name))).slice(0, 4).map((parameter, index) => (
            <Col key={index} span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(parameter.value, parameter.unit)}
              </div>
              <div>
                {parameter.name}
              </div>
            </Col>
          )) : platformEvent}
      </Row>
    </div>
  );
};

DatasetSummary.propTypes = {
  info: PropTypes.object,
  parameters: PropTypes.array,
  obsData: PropTypes.object,
};

DatasetSummary.defaultProps = {
  info: {},
  parameters: [],
  obsData: {},
};

export default memo(DatasetSummary);
