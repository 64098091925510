import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { preferredTemperatureUnit } from '../../../../utils';
import WaterTempIcon from '../../../icons/WaterTemp';
import CloseIcon from '../../../icons/Close';

const SurfaceWaterTemperature = (props) => {
  const {
    isActive,
    setIsActive,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const tempRange = isMobile ? [29.5, 25.5, 20.5, 15.5, 10.5, 5.5, 0] : [29.5, 23.5, 19.5, 15.5, 10.5, 6.5, 0];

  const [rangeDisplay, setRangeDisplay] = useState(
    <>
      {tempRange.map((tempRange, index) => (
        <React.Fragment key={index}>
          {isMobile && (
            <div className="mobile-temp-legend-item">
              <span className="mobile-temp-legend-item-text">
                {preferredTemperatureUnit(tempRange)}
              </span>
            </div>
          )}
          {!isMobile && (
            <div className="temp-legend-item">
              <span className="temp-legend-item-text">
                {preferredTemperatureUnit(tempRange)}
              </span>
            </div>
          )}
        </React.Fragment>
      ))}
    </>,
  );

  // https://stackoverflow.com/questions/61178240/useeffect-does-not-listen-for-localstorage
  // https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react
  useEffect(() => {
    function triggerSetRangeDisplay() {
      setRangeDisplay(
        <>
          {tempRange.map((tempRange, index) => (
            <React.Fragment key={index}>
              {isMobile && (
                <div className="mobile-temp-legend-item">
                  <span className="mobile-temp-legend-item-text">
                    {preferredTemperatureUnit(tempRange)}
                  </span>
                </div>
              )}
              {!isMobile && (
                <div className="temp-legend-item" key={index}>
                  <span className="temp-legend-item-text">
                    {preferredTemperatureUnit(tempRange)}
                  </span>
                </div>
              )}
            </React.Fragment>
          ))}
        </>,
      );
    }

    window.addEventListener('storage', triggerSetRangeDisplay);

    return () => {
      window.removeEventListener('storage', triggerSetRangeDisplay);
    };
  }, []);

  return (
    <>
      {isMobile
      && (
        <div className="mobile-temp-legend-container">
          <div className="mobile-temp-legend">
            {rangeDisplay}
          </div>
        </div>
      )}
      {!isMobile
        && (
          <div className={`map-layer-card ${isActive ? 'active' : ''}`} onClick={() => { if (!isActive) { setIsActive(true); } }}>
            <div className="map-layer-title">
              <div className="map-layer-icons">
                <WaterTempIcon />
                <CloseIcon className="close-button" onClick={() => setIsActive(false)} style={isActive ? {} : { display: 'none' }} />
              </div>
              <div>Water</div>
              <div>Temperature</div>
            </div>
            <div className={`map-layer-content ${isActive ? 'active' : ''}`}>
              <div className="map-layer-legend-container">
                <div className="legend temp-legend">
                  {rangeDisplay}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
SurfaceWaterTemperature.defaultProps = {
  isActive: false,
  setIsActive: () => {},
};
SurfaceWaterTemperature.propTypes = {
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
};

export default SurfaceWaterTemperature;
