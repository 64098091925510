import { Card } from 'antd';
import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { SettingIcon } from '../../../components/icons';
import PreferenceForm from './components/PreferenceForm';
import UnitPreferenceForm from './components/UnitPreferenceForm';
import TempPreferenceForm from './components/TempPreferenceForm';
import MapQueryTypes from '../../../services/query-types/map';
import { parameterConfigurations } from '../../../services/dataset.service';
import { updateSeagullUser } from '../../../services/user.service';
import UserContext from '../../../contexts/UserContext';

import {
  temperatureOptions,
  unitsOfMeasureOptions,
} from './constants';

import './styles.scss';

const Preferences = () => {
  const {
    authState, unitPreferences, unitPreferencesAction, seagullUser, setSeagullUser,
  } = useContext(UserContext);
  const [detailData, setDetailData] = useState();
  const [detailTempData, setDetailTempData] = useState();
  const [defaultUnit, setDefaultUnit] = useState();
  const [defaultTempUnit, setDefaultTempUnit] = useState();
  const [tempRadioValue, setTempRadioValue] = useState();

  useEffect(() => {
    let newDefaultTempUnit = defaultTempUnit;
    if (seagullUser) {
      const newDefaultUnit = defaultUnit || seagullUser.unit_preference;
      if (!_.isEqual(seagullUser.unit_preference, newDefaultUnit)) {
        if (newDefaultUnit === 'metric') {
          newDefaultTempUnit = 'celsius';
        } else {
          newDefaultTempUnit = 'fahrenheit';
        }
        setTempRadioValue(defaultTempUnit);
      }
      const newTempUnit = newDefaultTempUnit || seagullUser.temp_preference;
      const updatedUser = {
        ...seagullUser,
        temp_preference: newTempUnit,
        unit_preference: newDefaultUnit,
        unit_preferences: unitPreferences,
      };
      if (!_.isEqual(seagullUser.unit_preferences, unitPreferences)) {
        updateSeagullUser(updatedUser)
          .then((data) => {
            setSeagullUser(data);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
      }
    }
  }, [unitPreferences]);

  const compareParameters = (parameterA, parameterB) => {
    if (parameterA.display_name.en < parameterB.display_name.en) {
      return -1;
    }
    if (parameterA.display_name.en > parameterB.display_name.en) {
      return 1;
    }
    return 0;
  };

  const {
    data: parameterConfigurationsResult,
    refetch: parameterConfigurationsRefetch,
    status,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, {
    refetchOnWindowFocus: false,
    select: (data) => data.sort(compareParameters),
  });

  if (!parameterConfigurationsResult) {
    parameterConfigurationsRefetch();
  }

  const paramsList = !parameterConfigurationsResult ? null : parameterConfigurationsResult.map((elem) => {
    const userValue = _.isEmpty(unitPreferences) ? null : unitPreferences.find((e) => e.name === elem.standard_name);
    const metricValue = elem.preferred_metric_unit_id;
    const imperialValue = elem.preferred_imperial_unit_id;
    return ({
      name: elem.standard_name,
      label: elem.display_name.en,
      options: elem.units.map((u) => ({ value: u.id, label: u.symbol })),
      metricValue,
      imperialValue,
      value: userValue?.value || defaultUnit,
      pinned: userValue?.pinned,
    });
  });

  useEffect(() => {
    if (defaultUnit && paramsList) {
      const newUnitPrefs = paramsList.map((e) => ({ name: e.name, value: e[`${defaultUnit}Value`] }));
      unitPreferencesAction({
        type: 'REPLACE',
        value: newUnitPrefs,
      });
    }
  }, [defaultUnit, status]);

  useEffect(() => {
    if (defaultTempUnit && paramsList) {
      const newUnitPrefs = paramsList.map((e) => {
        if (e.value === 'celsius' || e.value === 'fahrenheit') {
          return { ...e, value: defaultTempUnit };
        }
        return e;
      });
      unitPreferencesAction({
        type: 'REPLACE',
        value: newUnitPrefs,
      });
    }
  }, [defaultTempUnit, status]);

  return (
    <div className="preferences-page">
      {!detailData && !detailTempData
        && (
          <div>
            <h2 className="h2 text-white">
              <SettingIcon />
              &nbsp;
              Preferences
            </h2>
            <Card>
              <div className="caption">
                Units
              </div>
              <div className="setting-item">
                <PreferenceForm
                  formData={unitsOfMeasureOptions}
                  setDetailData={setDetailData}
                  setDefaultUnit={setDefaultUnit}
                />
              </div>
              <div className="setting-item">
                <TempPreferenceForm
                  formData={temperatureOptions}
                  setDetailData={setDetailTempData}
                  setDefaultUnit={setDefaultTempUnit}
                  radioValue={tempRadioValue}
                  setRadioValue={setTempRadioValue}
                />
              </div>
            </Card>
            {(status === 'success') && (authState === 'cognitoHostedUI' || authState === 'signedin')
                && (
                  <>
                    <Card>
                      <div className="caption" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="left-item">Units</div>
                        <div className="right-item">Pin</div>
                      </div>
                      {paramsList.map((unit, idx) => (
                        <div key={idx} className="setting-item">
                          <UnitPreferenceForm
                            unit={unit}
                          />
                        </div>
                      ))}
                    </Card>
                  </>
                )}
          </div>
        )}

      {detailData && (
        <PreferenceForm
          formData={detailData}
          detailData
          setDetailData={setDetailData}
          setDefaultUnit={setDefaultUnit}
        />
      )}
      {detailTempData && (
        <TempPreferenceForm
          formData={detailTempData}
          detailData={detailTempData}
          setDetailData={setDetailTempData}
          setDefaultUnit={setDefaultTempUnit}
          radioValue={tempRadioValue}
          setRadioValue={setTempRadioValue}
        />
      )}
    </div>
  );
};

Preferences.propTypes = {};

export default Preferences;
