import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import {
  Card, Col, Row,
} from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import formatcoords from 'formatcoords';
import moment from 'moment';

import CustomSwitch from '../../../components/custom-switch';
import Parameter from '../../../components/parameter';
import MiniChart from '../../../components/custom-chart/MiniChart';
import {
  CloudIcon, NoDataIcon, RainIcon, SunIcon,
} from '../../../components/icons';
import { getPlatformWeather, getObsLatestData } from '../../../services/dataset.service';
import MapQueryTypes from '../../../services/query-types/map';
import { featureFlag } from '../../../constant';
import {
  getPreferredMeasurementUnit,
  getWorkspaceUrl,
  findMostRecentParameter,
} from '../../../utils';
import NoPlatformData from '../../../components/no-platform-data';
import appConfig, { brandingConfig } from '../../../config';
import PlatformNotifications from './PlatformNotifications';
import Loading from '../../../components/loading';
import Partners from './Partners';
import { updateSeagullUser } from '../../../services/user.service';
import UserContext from '../../../contexts/UserContext';
import { parameterGroups } from '../../../config/parameterGroups';
import ParameterFilters from '../../../components/parameter/ParameterFilters';
import HtmlText from '../../../components/html-text';
import '../styles.scss';
import DatasetMessage from '../../../components/dataset-message';
import { videoOrImage } from '../../../utils/webcam';

const ParametersList = (props) => {
  const {
    platform,
    dataView,
    isMobile,
    metadata,
    parameters,
    onGetChartData,
    onDetailChange,
    onClick,
    isLoading,
    isPlatformsConsole,
  } = props;
  const shouldRenderParamChart = !isPlatformsConsole;
  const shouldRenderNotifications = !isPlatformsConsole;
  const shouldRenderPartners = !isPlatformsConsole;
  const shouldRenderWeather = !isPlatformsConsole;
  const shouldRenderHorizontalRule = !isPlatformsConsole;
  const isSamplingLocation = !isPlatformsConsole;

  const mapContainerRef = useRef(null);

  const [categorySelected, setCategorySelected] = useState('');
  const [mapWidth, setMapWidth] = useState(600);
  const [categoryArray, setCategoryArray] = useState([]);

  const { data: observationalDataResult } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  const externalIdType = platform?.obs_dataset_platform_assignment?.platform?.external_id_type;

  // Filter out any parameters that are older than appConfig.oldDataCutoffInHours,
  // except for ESP sites.
  const activeParameters = externalIdType !== 'esp_site'
    ? parameters.filter((parameter) => {
      const timeSince = moment().diff(moment(parameter.date), 'hours');
      return timeSince < appConfig.oldDataCutoffInHours && !Number.isNaN(timeSince);
    })
    : parameters;

  useEffect(() => {
    const width = mapContainerRef.current.offsetWidth;
    if (width > 0) {
      setMapWidth(width);
    }
  }, [mapContainerRef, dataView]);

  const { seagullUser, unitPreferences, setSeagullUser } = useContext(UserContext);

  const {
    data: weatherData,
  } = useQuery(
    MapQueryTypes.REST_PLATFORM_WEATHER,
    getPlatformWeather,
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const updatedUser = {
      ...seagullUser,
      unit_preferences: unitPreferences,
    };
    updateSeagullUser(updatedUser)
      .then((data) => {
        setSeagullUser(data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [unitPreferences]);

  const getWeatherIcon = (weather) => {
    switch (weather) {
      case 'rainy':
        return <RainIcon />;
      case 'cloudy':
        return <CloudIcon />;
      case 'sunny':
        return <SunIcon />;
      default:
        break;
    }
  };

  const getDms = () => {
    if (platform?.deployment_site?.latitude) {
      const { latitude, longitude } = platform?.deployment_site;
      const decCoords = formatcoords(latitude, longitude).format(
        'ddX', { latLonSeparator: ', ' },
      );
      const dmsCoords = formatcoords(latitude, longitude).format(
        'DDMMssX', { latLonSeparator: ', ', decimalPlaces: 0 },
      );

      return (
        <span>
          {decCoords}
          <br />
          &#x28;
          {dmsCoords}
          &#x29;
        </span>
      );
    }

    return '';
  };

  const platformSummary = () => {
    if (metadata) {
      const summary = metadata.find((o) => o.attribute_name === 'summary');
      if (summary) {
        return <HtmlText string={summary.attribute_value} />;
      }
    }
    return '';
  };

  const onCategoriesChange = (category) => {
    setCategorySelected(category);
  };

  const platformObsData = observationalDataResult?.find(({ obs_dataset_id: obsDatasetId }) => obsDatasetId === platform?.obs_dataset_id);
  const mostRecentParameterTimestamp = findMostRecentParameter(platformObsData)?.observations[0].timestamp;
  const mostRecentParameterId = findMostRecentParameter(platformObsData)?.parameter_id;

  const workspaceUrl = getWorkspaceUrl(mostRecentParameterTimestamp, platformObsData?.obs_dataset_id, mostRecentParameterId);

  const getParameterWidgets = (parameters) => {
    // Ignore parameters with NaN values so the depth labels get rendered at depth transitions correctly
    const filteredParameters = parameters.filter((parameter) => {
      // Trying to match
      // components/custom-map/components/DatasetDetail:getParameterWidgets
      // Repeating some of the above logic towards a future refactor
      // date to be determined...
      const timeSince = moment().diff(moment(parameter.date), 'hours');
      if ((!isSamplingLocation && timeSince > appConfig.oldDataCutoffInHours && externalIdType !== 'esp_site')
        || Number.isNaN(timeSince)
        || Number.isNaN(parameter.value)) {
        return false;
      }
      if (!categorySelected) {
        return parameter;
      }
      const parameterGroup = parameterGroups.find((group) => group.name === categorySelected);
      // No filter no problem
      if (!parameterGroup) {
        return parameter;
      }
      // If we are... higher level filters will filter non-sampling old data out already...
      if (parameterGroup.parameters.includes(parameter.name)) {
        return parameter;
      }
      return false;
    });

    parameters.forEach((parameter) => {
      parameterGroups.forEach((group) => {
        if (group.parameters.includes(parameter.name) && !categoryArray.includes(group.name)) {
          setCategoryArray([...categoryArray, group.name]);
        }
      });
    });

    return filteredParameters.map((parameter, index) => {
      const previousParameter = filteredParameters[index - 1];

      const getParameterColor = () => {
        const parameterGroup = parameterGroups.find((group) => group.parameters.includes(parameter.name));

        if (!parameterGroup?.unSelectedColor) {
          return '#fff';
        }

        return parameterGroup.unSelectedColor;
      };

      const parameterColor = getParameterColor();

      return (
        <React.Fragment key={index}>
          {(!previousParameter || (parameter.depth !== previousParameter.depth)) && shouldRenderHorizontalRule
            && (
              <Col span={24} key={`depth-group-${parameter.depthGroup}`}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                  {!parameter.depth || parameter.depth === 0
                    ? <span style={{ marginRight: 15 }}>SURFACE</span>
                    : (
                      <span style={{ marginRight: 15, whiteSpace: 'nowrap' }}>
                        {getPreferredMeasurementUnit(parameter.depth)}
                        {' '}
                        DEPTH
                      </span>
                    )}
                  <div style={{ backgroundColor: isMobile ? brandingConfig.colors.grey : brandingConfig.colors.greyDark, height: 1, width: '100% ' }} />
                </div>
              </Col>
            )}
          <Col
            key={index}
            md={12}
            xs={24}
          >
            <Card
              className={classnames('parameter-card')}
              style={{
                borderStyle: 'none', borderRadius: 10, paddingBottom: 0, backgroundColor: isMobile ? '#081c2c' : brandingConfig.colors.primaryDark2,
              }}
              headStyle={{ border: 'none', paddingBottom: 0 }}
              bodyStyle={isMobile ? { padding: '0 0' } : { paddingTop: 0, paddingRight: 0 }}
            >
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => onClick(parameter)}
              >
                <Parameter
                  date={parameter.date}
                  name={parameter.name}
                  value={parameter.value}
                  unit={parameter.unit}
                  dataVerifiedVisible={false}
                  metricVisible={false}
                  style={isMobile ? { paddingTop: 5, paddingBottom: 5, flex: '0 0 60%' } : { flex: '0 0 60%' }}
                  pinned={parameter.pinned}
                  onClick={() => onClick(parameter)}
                  standardName={parameter.standard_name}
                  isMobile={isMobile}
                  isSnapshot
                />
                {parameter?.standard_name !== 'wind_from_direction' && shouldRenderParamChart
                  && (<MiniChart data={[{ data: onGetChartData(parameter) }]} color1={parameterColor} color2={brandingConfig.colors.primary} style={isMobile ? { marginTop: '20px' } : { flex: '0 0 40%' }} />)}
              </div>
            </Card>
          </Col>
        </React.Fragment>
      );
    });
  };

  return (
    <div
      className="parameter-list"
      style={{
        paddingTop: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        maxHeight: '100%',
      }}
    >
      {isMobile
        ? (
          <div style={{ position: 'relative', paddingLeft: 10, paddingRight: 10 }}>
            <CustomSwitch
              defaultChecked={!dataView}
              unCheckedChildren="Data"
              checkedChildren="Platform Info"
              className="detail-switch"
              onChange={onDetailChange}
            />
          </div>
        ) : ''}
      {isMobile && platform.obs_dataset_message?.message && (
        <DatasetMessage message={platform.obs_dataset_message?.message} />
      )}
      <Row wrap={false} className="parameter-list-inner" style={{ backgroundColor: brandingConfig.colors.primaryDark2 }}>
        <Col
          className="parameter-list-detail"
          hidden={isMobile && !dataView}
          flex={1}
          md={16}
          xs={24}
          style={!isMobile ? {
            backgroundColor: brandingConfig.colors.primaryDark2,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          } : {
            paddingLeft: 10, paddingRight: 10, paddingBottom: 20, backgroundColor: brandingConfig.colors.primaryDark,
          }}
        >
          {!isMobile && platform.obs_dataset_message?.message && (
            <DatasetMessage message={platform.obs_dataset_message?.message} />
          )}
          <div style={{
            display: 'flex', padding: isMobile ? '0px 20px 0px 0px' : '20px 20px 0 20px', justifyContent: isMobile ? 'center' : 'start', alignItems: 'center', flexWrap: 'wrap', gap: '10px', marginBottom: isMobile ? '20px' : '',
          }}
          >
            <ParameterFilters onCategoriesChange={onCategoriesChange} categorySelected={categorySelected} categoriesPresent={categoryArray} />
          </div>
          <div style={isMobile ? { padding: '0px' } : { padding: '20px' }}>
            <Row gutter={[isMobile ? 0 : 16, 16]}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {activeParameters && activeParameters.length > 0 && (
                    getParameterWidgets(activeParameters)
                  )}
                  {activeParameters.length < 1 && (
                    <NoPlatformData
                      icon={<NoDataIcon />}
                      message={(
                        <>
                          {platform.platform_event === 'recovered'
                            ? <p>It looks like this platform has been recovered for the season.</p>
                            : <p>This platform doesn&apos;t appear to be sending us data.</p>}
                          <p>
                            You can access the non real time data for this platform
                            {' '}
                            <Link to={workspaceUrl}>here</Link>
                            .
                          </p>
                        </>
                      )}
                    />
                  )}
                </>
              )}
            </Row>
          </div>
        </Col>
        <Col
          hidden={isMobile && dataView}
          flex={1}
          md={8}
          xs={24}
          className="bg-dark parameter-list-sidebar"
          style={{
            padding: 20,
            borderTopRightRadius: isMobile ? 0 : 10,
            borderBottomRightRadius: isMobile ? 0 : 10,
          }}
        >
          {featureFlag.notifications && shouldRenderNotifications
            && <PlatformNotifications obsDatasetId={platform.obs_dataset_id} />}

          <div className="obs-dataset-summary">
            <div className="h3">About</div>
            <p>
              {platformSummary()}
            </p>
          </div>

          {platform?.obs_dataset_platform_assignment?.platform.webcam_url
            && (
              <div className="webcam-feed">
                {videoOrImage(
                  platform?.obs_dataset_platform_assignment?.platform.webcam_url,
                  false,
                  isMobile,
                )}
              </div>
            )}

          <div style={{
            color: '#fff',
            borderRadius: 10,
            backgroundColor: 'rgba(255, 255, 255, .12) 40 40',
            marginTop: 21,
            overflow: 'hidden',
          }}
          >
            <div style={{ padding: 20 }}>
              <div className="h3">Location</div>
              <p>
                {platform && (
                  getDms()
                )}
              </p>
            </div>

            <div
              ref={mapContainerRef}
              style={{
                height: 200,
                position: 'relative',
              }}
            >
              <Link
                to={`/map?platform=${platform?.org_platform_id}`}
                style={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  position: isMobile ? 'relative' : 'absolute',
                  left: 4,
                  bottom: 20,
                }}
              >
                View in Map
              </Link>
              <img
                alt={`${platform.platform_name} Map`}
                src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${platform?.deployment_site?.longitude},${platform?.deployment_site?.latitude},5.5,0,60/${mapWidth}x200?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
              />
            </div>
          </div>

          {featureFlag.weather && shouldRenderWeather
            && (
              <div className="weather-container">
                <div className="title">
                  <div className="h3">{platform.platform_name}</div>
                  <div style={{ fontSize: 15, color: brandingConfig.colors.greyLight }}>WEATHER</div>
                </div>
                {weatherData
                  && (
                    <div className="top">
                      {getWeatherIcon(weatherData[1].weather)}
                      <label>{`${weatherData[1].maxTemp.value}${weatherData[1].maxTemp.unit}`}</label>
                    </div>
                  )}
                <div>
                  {weatherData && weatherData.map((item, index) => (
                    <Row className="item" key={index}>
                      <Col span={8} className="day">
                        {item.day}
                      </Col>
                      <Col span={8} className="max-temp">
                        {getWeatherIcon(item.weather)}
                        <label>{`${item.maxTemp.value}${item.maxTemp.unit}`}</label>
                      </Col>
                      <Col span={8} className="min-temp">
                        {`${item.minTemp.value}${item.minTemp.unit}`}
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            )}

          {featureFlag.sponsors && shouldRenderPartners && platform.obs_dataset_partners?.length > 0
            && (
              <Partners partners={platform.obs_dataset_partners} />
            )}
        </Col>
      </Row>
    </div>
  );
};

ParametersList.propTypes = {
  dataView: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPlatformsConsole: PropTypes.bool,
  metadata: PropTypes.array,
  onClick: PropTypes.func,
  onGetChartData: PropTypes.func,
  onDetailChange: PropTypes.func,
  platform: PropTypes.object,
  parameters: PropTypes.array,
};

ParametersList.defaultProps = {
  dataView: false,
  isMobile: false,
  isPlatformsConsole: false,
  metadata: [],
  parameters: [],
  platform: {},
  onClick: () => { },
  onGetChartData: () => { },
  onDetailChange: () => { },
  isLoading: true,
};

export default memo(ParametersList);
