import React from 'react';
import PropTypes from 'prop-types';
import { parameterGroups } from '../../config/parameterGroups';

const ParameterFilters = (props) => {
  const {
    onCategoriesChange, categorySelected, categoriesPresent, showAll,
  } = props;

  // TODO: categoryButton should probably be its own component
  const categoryButton = (name, unSelectedColor, selectedColor, icon, label) => {
    const isSelected = categorySelected === name;
    const isDisabled = !categoriesPresent.includes(name);

    const handleClick = () => {
      if (isDisabled || isSelected) {
        onCategoriesChange('');
      } else {
        onCategoriesChange(name);
      }
    };

    return (
      <button
        key={name}
        className={`pointer ${isSelected ? 'selected' : ''}`}
        onClick={handleClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: isSelected ? `1px solid ${selectedColor}` : `1px solid ${unSelectedColor}`,
          borderRadius: '15px',
          padding: '2px 8px',
          height: 30,
          backgroundColor: isSelected ? `${selectedColor}` : 'transparent',
          opacity: isDisabled ? 0.5 : 1,
          cursor: isDisabled ? 'auto' : 'pointer',
          fontSize: 14,
        }}
      >
        {icon}
        <div style={{ color: 'white' }}>{label}</div>
      </button>
    );
  };

  return (
    <>
      {parameterGroups.filter((group) => showAll || (group?.isVisibleByDefault ?? true)).map((parameterGroup) => {
        const {
          name, unSelectedColor, selectedColor, icon, label,
        } = parameterGroup;
        return categoryButton(name, unSelectedColor, selectedColor, icon, label);
      })}
    </>
  );
};

ParameterFilters.propTypes = {
  onCategoriesChange: PropTypes.func,
  categorySelected: PropTypes.string,
  categoriesPresent: PropTypes.array,
  showAll: PropTypes.bool,
};

ParameterFilters.defaultProps = {
  onCategoriesChange: () => { },
  categorySelected: '',
  categoriesPresent: [],
  showAll: false,
};

export default ParameterFilters;
