import { useState, useEffect } from 'react';
import MapboxWind from './MapboxWind';

const CurrentAnimation = (props) => {
  const {
    mapRef,
    UTCPath,
    selectedLake,
    selectedOverlay,
  } = props;

  const [mapLoaded, setMapLoaded] = useState(false);

  const selectedForecastId = selectedOverlay === 'water_current' ? 'water' : 'wind';

  const lakeTiles = [
    {
      id: 'leofs', name: 'Erie', tl_lon: -84.0, tl_lat: 43.25, br_lon: -78.0, br_lat: 41.25,
    },
    {
      id: 'lmofs', name: 'Michigan', tl_lon: -88.04, tl_lat: 46.36, br_lon: -84.79275, br_lat: 41.06,
    },
    {
      id: 'lhofs', name: 'Huron', tl_lon: -84.79275, tl_lat: 46.36, br_lon: -79.66, br_lat: 41.06,
    },
    {
      id: 'lsofs', name: 'Superior', tl_lon: -92.33, tl_lat: 49.08, br_lon: -84.25, br_lat: 46.37,
    },
    {
      id: 'loofs', name: 'Ontario', tl_lon: -79.98, tl_lat: 44.35, br_lon: -75.98, br_lat: 43.16,
    },
  ];

  const lakeFromName = (lakeName) => {
    const lake = lakeTiles.find((tile) => tile.name === lakeName);
    return lake;
  };

  const lakeBounds = (lake) => ([lake.tl_lon, lake.br_lat, lake.br_lon, lake.tl_lat]);

  function CustomAnimation(map, gl, lake) {
    let dataLoaded = false;
    const s3UrlBase = 'https://seagull-visualization-layers.s3.us-east-2.amazonaws.com';
    const fetchImage = async (zoomLevel, tileId) => {
      const imageUrl = `${s3UrlBase}${UTCPath}/${tileId}-${selectedForecastId}.png`;
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      return URL.createObjectURL(imageBlob);
    };

    const getJSON = (url, callback) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open('get', url, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          callback(xhr.response);
        }
        // else {
        //   throw new Error(xhr.statusText);
        // }
      };
      xhr.send();
    };

    const mapboxWind = new MapboxWind(map, gl);
    const bounds = lakeBounds(lake);
    const glofsUrl = `${s3UrlBase}${UTCPath}/${lake.id}-${selectedForecastId}.json`;

    const updateWind = (imageName) => {
      getJSON(glofsUrl, (windData) => {
        const windImage = new Image();
        // eslint-disable-next-line no-param-reassign
        windData.image = windImage;
        windImage.src = imageName;
        windImage.onload = () => {
          mapboxWind.setData({ data: windData, bounds });
          dataLoaded = true;
        };
      });
    };

    const showWind = async () => {
      const imageData = await fetchImage(5, lake.id);
      await updateWind(imageData);
    };
    showWind();

    const render = () => {
      if (!dataLoaded) return;

      mapboxWind.draw();
    };

    const startAnimation = () => {
      mapboxWind.startAnimation();
    };

    const stopAnimation = () => {
      mapboxWind.stopAnimation();
    };

    return { render, startAnimation, stopAnimation };
  }

  useEffect(() => {
    if (!mapLoaded) return;

    const selectedLakes = selectedLake !== '' ? [lakeFromName(selectedLake)] : lakeTiles;

    const unmountWind = selectedLakes.map((lake) => {
      const layerName = `${lake.id}-wind`;
      const map = mapRef.current.getMap();
      const mapLayer = map.getLayer(layerName);

      let customAnimation;

      const startAnimation = () => {
        try {
          customAnimation.startAnimation();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };

      const stopAnimation = () => {
        try {
          customAnimation.stopAnimation();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };

      const customLayer = {
        id: layerName,
        type: 'custom',
        onAdd: (map, mbxContext) => {
          customAnimation = new CustomAnimation(map, mbxContext, lake);
        },
        render: () => {
          customAnimation.render();
        },
      };

      const mapResize = () => {
        // Guard against adding a layer which has already been removed
        const mapLayer = map.getLayer(layerName);
        if (typeof mapLayer === 'undefined') return;

        // Replace map layers after a resize
        map.removeLayer(layerName);
        map.addLayer(customLayer);
      };

      if (typeof mapLayer !== 'undefined') {
        // Remove map layer
        map.removeLayer(layerName);
      }
      map.addLayer(customLayer);
      map.on('movestart', () => {
        // stop animation and clear canvas
        stopAnimation();
      });

      map.on('moveend', () => {
        // start animating again
        startAnimation();
      });

      map.on('resize', () => {
        mapResize();
      });

      return () => {
        stopAnimation();
        try {
          map.removeLayer(layerName);
        } catch (error) {
          // console.log(error);
        }
        map?.off('movestart', stopAnimation);
        map?.off('moveend', startAnimation);
        map?.off('resize', mapResize);
      };
    });
    return () => {
      unmountWind.forEach((unmount) => unmount());
    };
  }, [selectedLake, mapLoaded]);

  if (mapRef?.current) {
    const map = mapRef.current.getMap();
    if (!mapLoaded && map.loaded()) {
      setMapLoaded(true);
    }
    map.on('load', () => {
      setMapLoaded(true);
    });
    map.on('error', (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
    map.on('webglcontextlost', () => {
      // eslint-disable-next-line no-console
      console.log('WebGL context lost');
    });
    map.on('webglcontextrestored', () => {
      // eslint-disable-next-line no-console
      console.log('WebGL context restored');
    });
  }
  return (null);
};

export default CurrentAnimation;
