import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import CustomModal from '../../../components/modal';
import useSearchParams from './useSearchParams';
import '../styles.scss';

const SocialShareReceipientModal = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const searchParams = useSearchParams();
  const [showSocialShareReceipientModal, setShowSocialShareReceipientModal] = useState(false);
  const [chartImage, setChartImage] = useState();

  useEffect(() => {
    if (searchParams.has('share')) {
      const base64UrlSafeDecode = (str) => (atob(str?.replace(/-/g, '+').replace(/_/g, '/')));
      const imageObj = base64UrlSafeDecode(searchParams.get('share'));
      const imageId = JSON.parse(imageObj).i;
      setChartImage(`https://${process.env.REACT_APP_SHARING_BUCKET}.s3.us-east-2.amazonaws.com/${imageId}.svg`);
    }
  }, []);

  const toggleSocialShareReceipientModal = () => {
    setShowSocialShareReceipientModal(!showSocialShareReceipientModal);
  };

  useEffect(() => {
    const shareParam = searchParams.get('share');
    setShowSocialShareReceipientModal(shareParam != null);
  }, [searchParams]);

  return (
    <CustomModal
      visible={showSocialShareReceipientModal}
      onCancel={toggleSocialShareReceipientModal}
      footer={null}
      width="650px"
      className="SocialShareReceipientModal"
      style={{
        top: isMobile ? 0 : 'none', height: isMobile ? '100vh' : null, display: isMobile ? 'flex' : 'block', zIndex: 9999, marginTop: 0,
      }}
    >
      <h1 className="socialShareHeading">Dive Deep with Seagull</h1>
      <p style={{ color: 'white', fontSize: 16, marginBottom: isMobile ? 5 : null }}>
        Welcome to Seagull, your Great Lakes discovery platform for information and data! Explore current conditions, forecasts, and more. Plan your next adventure with confidence — Seagull puts Great Lakes data at your fingertips. Navigate with ease and discover the rich resources of these magnificent waters.
      </p>
      <img
        src={chartImage}
        alt="placeholder chart"
        style={{
          borderRadius: 25,
          width: '100%',
          height: 'auto',
          border: 'solid 1px #a3a3a3',
        }}
      />
      <div style={{
        display: 'flex', width: '100%', justifyContent: 'center', marginTop: 10,
      }}
      >
        <Button className="viewButton" onClick={toggleSocialShareReceipientModal}>
          View Current Conditions
        </Button>
      </div>
    </CustomModal>
  );
};

export default SocialShareReceipientModal;
