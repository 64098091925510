import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import DownloadButton from '../../../components/data-download/DownloadButton';
import DownloadModalFooter from '../../../components/data-download/DownloadModalFooter';

const DownloadChartModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { onDownloadPng, onDownloadSvg } = props;

  return (
    <>
      <div className="download-wrapper">
        <img src="/glosLogo.png" id="logo" alt="" style={{ display: 'none' }} />
        <p
          className="share-modal-header"
        >
          Download hi-res image of graph
        </p>
        <div style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          color: 'white',
          justifyContent: isMobile ? 'space-between' : 'center',
          textAlign: 'center',
          alignItems: 'center',
        }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: isMobile ? '25px' : 0,
              paddingRight: isMobile ? 0 : '65px',
              paddingLeft: isMobile ? 0 : '65px',
              flex: 1,
            }}
            className="bodyText"
          >
            <h3 className="subHeading">PNG</h3>
            <p>Get a high-resolution PNG image of the selected graph.</p>
            <DownloadButton onClick={onDownloadPng} text="Download PNG" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderLeft: isMobile ? 'none' : 'solid 1px white',
              borderTop: isMobile ? 'solid 1px white' : 'none',
              padding: isMobile ? 25 : '40px 65px',
              flex: 1,
            }}
            className="bodyText"
          >
            <h3 className="subHeading">SVG</h3>
            <p>Get a high-resolution SVG image of the selected graph.</p>
            <DownloadButton onClick={onDownloadSvg} text="Download SVG" />
          </div>
        </div>
      </div>
      <DownloadModalFooter />
    </>
  );
};

DownloadChartModal.propTypes = {
  onDownloadPng: PropTypes.func,
  onDownloadSvg: PropTypes.func,
};

DownloadChartModal.defaultProps = {
  onDownloadPng: () => {},
  onDownloadSvg: () => {},
};

export default DownloadChartModal;
