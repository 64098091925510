import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import geobuf from 'geobuf';
import Pbf from 'pbf';
import { Layer, Source } from 'react-map-gl';
import { useMediaQuery } from 'react-responsive';
import { preferredWindMagnitudeUnit } from '../../../utils';

const s3UrlBase = 'https://seagull-visualization-layers.s3.us-east-2.amazonaws.com';

const WindMagnitude = (props) => {
  const {
    UTCPath,
    selectedLake,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isLoading, setIsLoading] = useState(false);
  const [windLayers, setWindLayers] = useState([]);

  const fetchOfs = async (ofs) => {
    const ofsUrl = `${s3UrlBase}${UTCPath}/${ofs}-wind.pbf`;
    const ofsResponse = await fetch(ofsUrl);
    const ofsData = await ofsResponse.arrayBuffer();
    return geobuf.decode(new Pbf(ofsData));
  };

  const fetchData = async (selectedLake) => {
    if (selectedLake.length > 0) {
      const ofs = `l${selectedLake[0][0].toLowerCase()}ofs`;
      const ofsData = await fetchOfs(ofs);
      setWindLayers([
        {
          lake: 'primaryLake',
          sourceId: 'primaryLakeSource',
          data: ofsData,
        },
      ]);
    } else if (selectedLake.length === 0) {
      const [leofsData, lhofsData, lmofsData, loofsData, lsofsData] = await Promise.all([
        fetchOfs('leofs'),
        fetchOfs('lhofs'),
        fetchOfs('lmofs'),
        fetchOfs('loofs'),
        fetchOfs('lsofs'),
      ]);
      setWindLayers([
        {
          lake: 'primaryLake',
          sourceId: 'primaryLakeSource',
          data: leofsData,
        },
        {
          lake: 'lhofs',
          sourceId: 'lhofsSource',
          data: lhofsData,
        },
        {
          lake: 'lmofs',
          sourceId: 'lmofsSource',
          data: lmofsData,
        },
        {
          lake: 'loofs',
          sourceId: 'loofsSource',
          data: loofsData,
        },
        {
          lake: 'lsofs',
          sourceId: 'lsofsSource',
          data: lsofsData,
        },
      ]);
    } setIsLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      fetchData(selectedLake)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [selectedLake, UTCPath]);

  const windStyle = {
    type: 'fill',
    beforeId: 'waterway-label',
    paint: {
      'fill-color':
        [
          'interpolate',
          [
            'cubic-bezier',
            1,
            1,
            0.4,
            0.7,
          ],
          ['get', 'level'],
          0,
          // 'hsl(268, 48%, 26%)',
          'rgba(0,0,0,0)',
          1,
          'rgba(0,0,0,0)',
          2,
          'rgba(0,0,0,0)',
          3,
          '#00d4b0',
          // 'rgba(0,0,0,0)',
          4,
          'hsla(88,92%,45%,0.7)',
          // 'rgba(0,0,0,0)',
          5,
          'hsl(73, 91%, 49%)',
          6,
          'rgba(255,254,12,0.7)',
          7,
          'rgba(251,190,26,0.7)',
          8,
          'rgba(246,125,40,0.7)',
          9,
          'rgba(255,0,38,0.7)',
          10,
          'rgba(232,30,118,0.7)',
          11,
          'rgba(172,6,6,0.7)',
          12,
          'rgba(114,2,2,0.27)',
          15,
          'rgba(75,0,0,0.7)',
        ],
      'fill-opacity': 1,
      'fill-antialias': true,
    },
  };
  const windRange = isMobile
    ? [
      16,
      14,
      12,
      9,
      6,
      3,
      0,
    ]
    : [
      13,
      12,
      11,
      10,
      9,
      8,
      7,
      6,
      5,
      4,
      3,
      2,
      0,
    ];
  return (
    <>
      {isMobile
      && (
        <div className="mobile-temp-legend-container">
          <div className="mobile-wind-legend">
            {windRange.map((windRange, index) => (
              <div className="mobile-temp-legend-item" key={index}>
                <span className="mobile-temp-legend-item-text">
                  {preferredWindMagnitudeUnit(windRange)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {windLayers.map((windLayer, index) => (
        <Source
          id={windLayer.sourceId}
          type="geojson"
          data={windLayer.data}
          key={index}
        />
      ))}
      {windLayers.map((windLayer, index) => (
        <Layer
          id={windLayer.lake}
          source={windLayer.sourceId}
          {...windStyle}
          key={index}
        />
      ))}
    </>
  );
};
WindMagnitude.defaultProps = {
  UTCPath: '',
  selectedLake: '',
};
WindMagnitude.propTypes = {
  UTCPath: PropTypes.string,
  selectedLake: PropTypes.string,
};

export default WindMagnitude;
