import React, {
  memo, useEffect, useState, useRef, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, {
  FlyToInterpolator,
  Popup,
  WebMercatorViewport,
} from 'react-map-gl';
import { useLocation } from 'react-router';
import useSupercluster from 'use-supercluster';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import formatcoords from 'formatcoords';
import appConfig, { brandingConfig } from '../../config/index';
import DatasetSummary from './components/DatasetSummary';
import OmicsDatasetSummary from './components/OmicsDatasetSummary';
import ESPDatasetSummary from './components/ESPDatasetSummary';
import DatasetDetail from './components/DatasetDetail';
import {
  getParameters,
  preferredWindMagnitudeUnit,
  preferredWaterMagnitudeUnit,
  preferredTemperatureUnit,
  platformGroupings,
  espStandardNames,
} from '../../utils';
import TimeSlider from './components/TimeSlider';
import filePathFromDate from './components/FilePathFromDate';
import SurfaceWaterTemperature from './components/SurfaceWaterTemperature';
import CurrentAnimation from './components/CurrentAnimation';
import WaterMagnitude from './components/WaterMagnitude';
import WindMagnitude from './components/WindMagnitude';
import MapOverlayDrawer from './components/map-overlay-drawer';
import { ForecastEvent, MapFilterEvent, PlatformMarkerEvent } from '../../constant/google-analytics/constant';
import Cluster from './components/Cluster';
import Trajectory from './components/Trajectory';
import UserContext from '../../contexts/UserContext';
import { featureFlag } from '../../constant';
import './styles.scss';
import SelectedFilters from './components/SelectedFilters';

const mapStyle = 'mapbox://styles/glos-mapbox/ckqgzhuf109u418pjzwu72l7c';

const startingUTCPath = filePathFromDate(new Date());

const CustomMap = (props) => {
  const location = useLocation();
  const mapRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const DEFAULT_VIEWPORT = location.pathname === '/landing' ? {
    latitude: isMobile ? brandingConfig.map.landingLatitudeMobile : brandingConfig.map.landingLatitude,
    longitude: isMobile ? brandingConfig.map.landingLongitudeMobile : brandingConfig.map.landingLongitude,
    zoom: brandingConfig.map.landingZoom,
  } : {
    latitude: brandingConfig.map.latitude,
    longitude: brandingConfig.map.longitude,
    zoom: brandingConfig.map.zoom,
  };

  const {
    children,
    datasets,
    filterVisible,
    selectedLake,
    selectedOverlay,
    selectedTags,
    datasetSummaries,
    registeredParameters,
    observationalPlatforms,
    organizations,
    obsData,
    parameterConfigurations,
    pushToHistory,
    onTagSelect,
    tagsList,
    filterFavorites,
    favoritePlatformData,
    datasetTrajectories,
    showPaths,
    omicsFilters,
    setOmicsFilters,
    obsDatasetsLatest,
  } = props;

  const [buoyData, setBuoyData] = useState([]);
  const [samplingLocationData, setSamplingLocationData] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [contourInfo, setContourInfo] = useState(null);
  const [clickableContourLayers, setClickableContourLayers] = useState([]);
  const [activeUTCPath, setActiveUTCPath] = useState(startingUTCPath);
  const [urlCoords, setUrlCoords] = useState('');
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

  const { unitPreferences } = useContext(UserContext);

  const updateUrlCoords = useCallback(
    _.debounce((coords) => {
      pushToHistory('coords', coords);
      setUrlCoords(coords);
    },
    100),
    [],
  );

  const updateUrlPlatform = useCallback(
    _.debounce((platform) => {
      pushToHistory('platform', platform);
    },
    100),
    [],
  );

  const getDms = (latitude, longitude) => {
    const decCoords = formatcoords(latitude, longitude).format(
      'ddX', { latLonSeparator: ', ', decimalPlaces: 3 },
    );
    const dmsCoords = formatcoords(latitude, longitude).format(
      'DDMMssX', { latLonSeparator: ', ', decimalPlaces: 0 },
    );

    return (
      <span>
        {decCoords}
        <br />
        &#x28;
        {dmsCoords}
        &#x29;
      </span>
    );
  };

  const getBoundsForPoints = (points) => {
    // Calculate corner values of bounds
    if (points.length === 0) {
      return null;
    }
    const pointsLong = points.map((point) => point.coordinates[0]);
    const pointsLat = points.map((point) => point.coordinates[1]);
    const cornersLongLat = [
      [Math.min(...pointsLong), Math.min(...pointsLat)],
      [Math.max(...pointsLong), Math.max(...pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 1000,
      height: 600,
    }).fitBounds(cornersLongLat, { padding: 50 }); // Can also use option: offset: [0, -100]
    const {
      longitude,
      latitude,
    } = viewport;
    return {
      longitude,
      latitude,
      zoom: DEFAULT_VIEWPORT.zoom,
    };
  };

  const handleSliderChange = useCallback(
    _.debounce((newUTCPath) => {
      setActiveUTCPath(newUTCPath);
    },
    500),
    [],
  );

  useEffect(() => {
    const longitude = viewport.longitude.toFixed(7);
    const latitude = viewport.latitude.toFixed(7);
    const zoom = String(Math.round(viewport.zoom * 10) / 10);

    if (location.pathname.includes('map')) {
      updateUrlCoords(encodeURI(`${longitude},${latitude},${zoom}`));

      updateUrlPlatform(selectedPlatform?.properties?.org_platform_id);
    }
  }, [selectedPlatform, viewport]);

  useEffect(() => {
    switch (selectedOverlay) {
      case 'surface_water_temperature':
        ReactGA.event(ForecastEvent.WaterTempOn);
        break;
      case 'water_current':
        ReactGA.event(ForecastEvent.WaterCurrentOn);
        break;
      default:
        break;
    }
  }, [selectedOverlay]);

  useEffect(() => {
    if (location.pathname === '/landing' && !location.search.includes('coords')) {
      // setViewport({
      //   latitude: 44.858323,
      //   longitude: -89.131259,
      //   zoom: 5.5,
      // });
      setViewport(DEFAULT_VIEWPORT);
    }
    if (location.pathname === '/map' && !location.search.includes('coords') && !selectedPlatform) {
      // setViewport({
      //   latitude: 44.149,
      //   longitude: -84.131259,
      //   zoom: 5.5,
      // });
      setViewport(DEFAULT_VIEWPORT);
    }
  }, [location.pathname]);

  // Currently catering Omics with parameter filter
  const addSamplingLocationObservations = (samplingLocations) => {
    const locations = [...samplingLocations];

    const retLocations = [];

    Object.keys(obsDatasetsLatest).forEach((key) => {
      const index = samplingLocations.findIndex((p) => p.properties.obs_dataset_id === obsDatasetsLatest[key].obs_dataset_id);
      if (index >= 0) {
        if (obsDatasetsLatest[key].observations[0].value.toFixed(2) !== '0.00') {
          locations[index].properties.observations = obsDatasetsLatest[key].observations;
          retLocations.push(locations[index]);
        }
      }
    });
    return retLocations;
  };

  const resetSamplingLocationObservations = (samplingLocations) => samplingLocations.map((s) => ({
    ...s,
    properties: {
      ...s.properties,
      observations: undefined,
    },
  }));

  const setMapData = () => {
    let filteredPlatforms = observationalPlatforms;
    if (observationalPlatforms && observationalPlatforms.length > 0 && datasetSummaries?.length > 0 && obsData) {
      if (filterFavorites && favoritePlatformData) {
        const favoriteIds = favoritePlatformData.map((o) => o.obs_dataset_id);
        // filter out platforms that are not in favorites
        filteredPlatforms = observationalPlatforms.filter((o) => favoriteIds.includes(o.properties.obs_dataset_id));
      }

      // filter out sampling_locations
      const continuousMonitoringPlatforms = (
        platformGroupings.buoy.join(
          platformGroupings.tower,
        )
      );
      const buoys = filteredPlatforms.filter((o) => continuousMonitoringPlatforms.includes(o.properties?.platform_type));
      let samplingLocations = filteredPlatforms
        .filter((o) => platformGroupings.sampling_location.includes(o.properties?.platform_type));

      if (selectedOverlay !== 'omics' || omicsFilters.filter === 'All') {
        samplingLocations = resetSamplingLocationObservations(samplingLocations);
      } else if (obsDatasetsLatest && omicsFilters.filter) {
        samplingLocations = addSamplingLocationObservations(samplingLocations);
      }

      setBuoyData(buoys.map((platform) => {
        // get organization_id from obs-dataset-summaries to add to properties
        const organizationId = datasetSummaries.find((d) => d.obs_dataset_id === platform.properties.obs_dataset_id)?.organization_id;
        return ({
          ...platform,
          properties: {
            ...platform.properties,
            cluster: false,
            organization_id: organizationId,
          },
        });
      }));

      setSamplingLocationData(samplingLocations.map((platform) => ({
        ...platform,
        properties: {
          ...platform.properties,
          platform_id: datasetSummaries.find((d) => platform.properties.obs_dataset_id === d.obs_dataset_id).obs_dataset_platform_assignment.platform_id,
          cluster: false,
        },
      })));
    } else {
      setBuoyData([]);
      setSamplingLocationData([]);
    }

    if (location.pathname === '/landing') {
      // setViewport({
      //   latitude: 44.149,
      //   longitude: -89.131259,
      //   zoom: 5.5,
      // });
    } else if (selectedLake === '' && location.pathname !== '/landing' && !selectedPlatform) {
      const urlSearchQuery = new URLSearchParams(location.search);
      if (urlSearchQuery.get('coords')) {
        const [lng, lat, zoom] = urlSearchQuery.get('coords')
          .split(',');
        setViewport({
          latitude: lat ? Number(lat) : 44.149,
          longitude: lng ? Number(lng) : -84.131259,
          zoom: zoom ? Number(zoom) : viewport.zoom,
        });
      }
    } else if (selectedLake === 'Ontario') {
      setViewport({
        latitude: 43.651070,
        longitude: -79.347015,
        zoom: 7,
      });
    } else if (selectedLake !== '' && !selectedPlatform) {
      if (filteredPlatforms && filteredPlatforms.length > 0) {
        const points = filteredPlatforms.map((item) => item.geometry).filter((mitem) => mitem !== undefined);
        const bounds = getBoundsForPoints(points);
        if (bounds) {
          setViewport(bounds);
        } else {
          setViewport(DEFAULT_VIEWPORT);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedOverlay !== 'omics') {
      const updatedSamplingLocationData = resetSamplingLocationObservations(samplingLocationData);
      setSamplingLocationData(updatedSamplingLocationData);
    }
  }, [selectedOverlay]);

  useEffect(() => {
    setMapData();
  }, [observationalPlatforms, selectedLake, favoritePlatformData, filterFavorites, datasetSummaries]);

  useEffect(() => {
    if (selectedOverlay === 'omics' && obsDatasetsLatest && omicsFilters.filter) {
      if (omicsFilters.filter.toLowerCase() === 'all') {
        const updatedSamplingLocationData = resetSamplingLocationObservations(samplingLocationData);
        setSamplingLocationData(updatedSamplingLocationData);
      } else {
        const samplingLocations = [...samplingLocationData];

        setSamplingLocationData(addSamplingLocationObservations(samplingLocations));
      }
    }
  }, [obsDatasetsLatest]);

  // empty dependency array means it only runs on initial render
  useEffect(() => {
    const urlSearchQuery = new URLSearchParams(location.search);
    const queryPlatformId = urlSearchQuery.get('platform');
    if (urlSearchQuery.get('coords')) {
      const [lng, lat, zoom] = urlSearchQuery.get('coords')
        .split(',');
      if (Number(lng)
        .toFixed(7) !== viewport.longitude.toFixed(7)
        && Number(lat)
          .toFixed(7) !== viewport.latitude.toFixed(7)) {
        setViewport({
          latitude: Number(lat),
          longitude: Number(lng),
          zoom: zoom ? Number(zoom) : viewport.zoom,
        });
      }
    }

    if (observationalPlatforms
      && observationalPlatforms.length > 0
      && queryPlatformId
      && selectedPlatform?.properties?.org_platform_id !== queryPlatformId
    ) {
      setSelectedPlatform(observationalPlatforms.find((o) => o.properties?.org_platform_id === queryPlatformId));
    }
  }, [location.search]);

  const removeCanvas = (currentType) => {
    const lakes = ['leofs', 'lhofs', 'lmofs', 'lsofs', 'loofs'];
    for (let i = 0; i < lakes.length; i++) {
      const el = document.getElementById(`${lakes[i]}-${currentType}`);
      if (el) {
        el.remove();
      }
    }
  };

  useEffect(() => {
    // Lake selection and forecast toggling
    if (selectedOverlay === 'water_current') {
      removeCanvas('wind');
    } else if (selectedOverlay === 'surface_water_temperature') {
      removeCanvas('water');
      removeCanvas('wind');
    } else if (selectedOverlay === 'wind_current') {
      removeCanvas('water');
    } else if (selectedOverlay === 'omics') {
      removeCanvas('water');
      removeCanvas('wind');
    } else {
      removeCanvas('water');
      removeCanvas('wind');
    }
    if (selectedOverlay.length === 0 || selectedOverlay === 'omics') {
      setClickableContourLayers([]);
    } else if (selectedLake.length > 0) {
      setClickableContourLayers(['primaryLake']);
    } else if (selectedLake.length === 0) {
      setClickableContourLayers(['primaryLake', 'lhofs', 'lmofs', 'loofs', 'lsofs']);
    }
    return () => {
    };
  }, [selectedTags, selectedLake, selectedOverlay, location.pathname]);

  const getNearestPlatform = (dataset, pos) => {
    let nearest;

    const obsAssetsData = observationalPlatforms.map((asset) => ({
      ...asset,
      distance: asset.geometry.coordinates[0] - dataset.geometry.coordinates[0],
    }));
    const obsAssetsDataSorted = obsAssetsData.slice()
      .sort((a, b) => a.distance - b.distance);

    // iterate over objects and calculate distance between them
    for (let i = 0; i < obsAssetsDataSorted.length; i += 1) {
      if (obsAssetsDataSorted[i].properties.org_platform_id === dataset.properties.org_platform_id) {
        if (pos === 'prev') {
          const prevKey = i === 0 ? obsAssetsDataSorted.length - 1 : i - 1;
          nearest = obsAssetsDataSorted[prevKey];
        } else if (pos === 'next') {
          const nextKey = i + 1;
          nearest = nextKey === obsAssetsDataSorted.length ? obsAssetsDataSorted[0] : obsAssetsDataSorted[nextKey];
        }
      }
    }
    return nearest;
  };

  const getParametersData = (dataset) => {
    if (dataset) {
      // navigate to marker
      const [longitude, latitude] = dataset.geometry.coordinates;

      setViewport({
        latitude,
        longitude,
        zoom: viewport.zoom,
      });

      const parameterData = getParameters({
        dataset: dataset.properties, datasetSummaries, obsData, parameterConfigurations, registeredParameters, unitPreferences,
      });

      setParameters(parameterData);

      // Get prev and next platforms
      const prevPlatform = getNearestPlatform(dataset, 'prev');
      const nextPlatform = getNearestPlatform(dataset, 'next');

      setPlatforms([prevPlatform, nextPlatform]);

      // set current dataset
      setSelectedPlatform(dataset);
    }
  };

  const handleAction = (dataset) => {
    setTooltipInfo(dataset);

    if (dataset) {
      const parameterData = getParameters({
        dataset: dataset.properties, datasetSummaries, obsData, parameterConfigurations, registeredParameters, unitPreferences,
      });
      setParameters(parameterData);
    }
  };

  const handleClick = (dataset) => {
    setSelectedPlatform(dataset);
    setShowDetail(true);
    setTooltipInfo(null);

    // Get prev and next platforms
    const prevPlatform = getNearestPlatform(dataset, 'prev');
    const nextPlatform = getNearestPlatform(dataset, 'next');

    setPlatforms([prevPlatform, nextPlatform]);
    if (dataset) {
      if (platformGroupings.sampling_location.includes(dataset.properties.platform_type)) {
        const targetObsDataset = obsDatasetsLatest.filter((odl) => odl.obs_dataset_id === dataset.properties.obs_dataset_id)[0];
        const sampleObsData = [{
          obs_dataset_id: targetObsDataset.obs_dataset_id,
          parameters: targetObsDataset.observations.map((os) => ({
            parameter_id: os.parameter_id,
            observations: [
              {
                qartod: 9,
                timestamp: new Date(os.obs_timestamp).getTime() / 1000,
                value: os.value,
              },
            ],
          })),
        }];
        const sampleParameterData = getParameters({
          dataset: dataset.properties, datasetSummaries, obsData: sampleObsData, parameterConfigurations, registeredParameters, unitPreferences,
        });
        setParameters(sampleParameterData);
      } else {
        const parameterData = getParameters({
          dataset: dataset.properties, datasetSummaries, obsData, parameterConfigurations, registeredParameters, unitPreferences,
        });
        setParameters(parameterData);
      }
    }
    ReactGA.event(PlatformMarkerEvent.MarkerClicked);
    ReactGA.event({
      ...PlatformMarkerEvent.SelectedPlatformId,
      label: `${dataset.properties.org_platform_id} Clicked on Map for Snapshot`,
    });
  };

  const getOrganizationName = React.useMemo(() => (dataset) => {
    const datasetRecord = datasets.filter((o) => o.org_platform_id === dataset.org_platform_id);
    if (datasetRecord?.length === 0) {
      return '';
    }
    const organization = organizations.filter((o) => o.organization_id === datasetRecord[0].organization_id);

    return organization[0].name;
  }, [datasets, organizations]);

  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  const isFavorite = (datasetId) => {
    if (favoritePlatformData) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
        return false;
      }
      const foundFavorite = favoritePlatformData?.find((p) => p.obs_dataset_id === datasetId);
      if (foundFavorite) {
        return true;
      }
    }

    return false;
  };

  // get clusters
  const {
    clusters: continuousMonitoringClusters,
    supercluster: continuousMonitoringSuperCluster,
  } = useSupercluster({
    points: buoyData,
    bounds,
    zoom: viewport.zoom,
    options: {
      radius: 40,
      maxZoom: 20,
      map: (props) => ({ ...props }),
      reduce: (acc, p) => {
        // https://github.com/leighhalliday/use-supercluster#map--reduce-options
        // with reduce we only have access to the accumulator and current - not sure of a cleaner way to handle this and get the right result
        if (p.platform_event === 'decommissioned'
          && acc.platform_event !== 'recovered'
          && acc.platform_event !== 'temporarily_recovered'
          && acc.platform_event !== 'deactivated'
          && acc.platform_event !== 'decommissioned'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'decommissioned';
        }

        if (p.platform_event === 'recovered'
          && acc.platform_event !== 'temporarily_recovered'
          && acc.platform_event !== 'deactivated'
          && acc.platform_event !== 'decommissioned'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'recovered';
        }

        if (p.platform_event === 'temporarily_recovered'
          && acc.platform_event !== 'deactivated'
          && acc.platform_event !== 'decommissioned'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'temporarily_recovered';
        }

        if (p.platform_event === 'deactivated'
          && acc.platform_event !== 'decommissioned'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'deactivated';
        }

        if (p.platform_event === 'decommissioned'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'decommissioned';
        }

        if (p.platform_event === 'unavailable'
          && acc.platform_event !== 'activated') {
          acc.platform_event = 'unavailable';
        }
        if (p.platform_event === 'activated') {
          acc.platform_event = 'activated';
        }
        if (
          p.platform_event === 'activated'
          && p.last_updated_parameter_in_hours < appConfig.oldDataCutoffInHours
        ) {
          acc.platform_event = 'activated';
          acc.last_updated_parameter_in_hours = p.last_updated_parameter_in_hours;
        }

        // if any platform is a favorite, make the cluster show this
        if (isFavorite(p.obs_dataset_id)) {
          acc.is_favorite = true;
        }

        return acc;
      },
    },
  });

  // get sampling location clusters
  const {
    clusters: samplingLocationClusters,
    supercluster: samplingLocationSupercluster,
  } = useSupercluster({
    points: samplingLocationData,
    bounds,
    zoom: viewport.zoom,
    options: {
      radius: 40,
      maxZoom: 20,
      map: (props) => ({ ...props }),
      reduce: (acc) => {
        acc.platform_type = 'sampling_location';
        return acc;
      },
    },
  });

  const handleClusterClick = (cluster, latitude, longitude) => {
    const expansionZoom = Math.min(
      continuousMonitoringSuperCluster.getClusterExpansionZoom(cluster.id),
      20,
    );

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: expansionZoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      transitionDuration: 'auto',
    });
    ReactGA.event(PlatformMarkerEvent.ClusterClicked);
  };

  const handleSamplingLocationClusterClick = (cluster, latitude, longitude) => {
    const expansionZoom = Math.min(
      samplingLocationSupercluster.getClusterExpansionZoom(cluster.id),
      20,
    );

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom: expansionZoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 2,
      }),
      transitionDuration: 'auto',
    });
    ReactGA.event(PlatformMarkerEvent.ClusterClicked);
  };

  const samplingLocationClustersContent = React.useMemo(() => (
    <Cluster
      mapData={samplingLocationClusters}
      showDetail={showDetail}
      selectedPlatform={selectedPlatform}
      handleAction={handleAction}
      handleClick={handleClick}
      handleClusterClick={handleSamplingLocationClusterClick}
    />
  ), [samplingLocationClusters, samplingLocationData, selectedPlatform, showDetail]);

  const continuousMonitoringClustersContent = React.useMemo(() => (
    <Cluster
      mapData={continuousMonitoringClusters}
      showDetail={showDetail}
      selectedPlatform={selectedPlatform}
      handleAction={handleAction}
      handleClick={handleClick}
      handleClusterClick={handleClusterClick}
    />
  ), [continuousMonitoringClusters, buoyData, selectedPlatform, showDetail]);

  if (selectedPlatform && !showDetail) {
    handleClick(selectedPlatform);
  }

  const handleShowDetail = (status) => {
    setShowDetail(status);
    setSelectedPlatform(null);
  };

  const onContourClick = useCallback((event) => {
    if (clickableContourLayers) {
      const {
        features,
      } = event;
      const clickedFeature = features && features[0];
      setContourInfo(
        clickedFeature
          ? {
            feature: clickedFeature,
            clickLon: event.lngLat[0],
            clickLat: event.lngLat[1],
          }
          : null,
      );
    }
  }, []);

  useEffect(() => {
    if (contourInfo) {
      setContourInfo(null);
    }
  }, [viewport]);

  // Listen for changes in storage
  useEffect(() => {
    function nullifyContourInfo() {
      setContourInfo(null);
    }

    window.addEventListener('storage', nullifyContourInfo);

    return () => {
      window.removeEventListener('storage', nullifyContourInfo);
    };
  }, []);

  useEffect(() => {
    if (location.search) {
      ReactGA.event({
        ...MapFilterEvent.MapFilterParams,
        label: location.search,
      });
    }
  }, [location.search]);

  // Removes data older than 24 hours from parameters in tooltip
  const filteredParameters = parameters.filter((parameter) => {
    const currentDate = new Date();
    const parameterTimestamp = new Date(parameter.date);
    const timeDifference = currentDate - parameterTimestamp;
    const millisecondsIn24Hours = 86400000;

    if (timeDifference > millisecondsIn24Hours) {
      return false;
    }
    return true;
  });

  const espParameters = parameters.filter((parameter) => espStandardNames.indexOf(parameter.standard_name) !== -1);

  return (
    <>
      {!isMobile && (
        <MapOverlayDrawer
          onTagsSelect={onTagSelect}
          tagsList={tagsList}
          selectedOverlay={selectedOverlay}
          omicsFilters={omicsFilters}
          setOmicsFilters={setOmicsFilters}
        />
      )}
      {!filterVisible && showDetail && parameters && platforms && selectedPlatform && (
        <DatasetDetail
          dataset={selectedPlatform}
          parameters={parameters}
          platforms={platforms}
          visible={showDetail}
          getParameters={getParametersData}
          obsData={obsData}
          handleShowDetail={handleShowDetail}
          getOrganizationName={getOrganizationName}
        />
      )}
      <ReactMapGL
        {...viewport}
        className="custom-map"
        width="100%"
        height="100%"
        maxZoom={20}
        maxPitch={0}
        dragRotate={false}
        touchRotate={false}
        mapStyle={mapStyle}
        ref={mapRef}
        onViewportChange={(viewport) => setViewport(viewport)}
        interactiveLayerIds={clickableContourLayers}
        onClick={onContourClick}
      >
        {(selectedTags?.some((t) => t.key === 'moving') && datasetTrajectories?.features?.length > 1) && (
          datasetTrajectories.features.map((trajectory, index) => (
            <Trajectory
              trajectory={trajectory}
              key={index}
              showPaths={showPaths}
            />
          ))
        )}

        {continuousMonitoringClustersContent}
        {/* {featureFlag.omics && (
          <CollectionSites
            observationalPlatforms={observationalPlatforms}
            viewport={viewport}
            setViewport={setViewport}
            bounds={bounds}
          />
        )} */}

        {featureFlag.omics && samplingLocationClustersContent}

        {location.pathname !== '/landing' && tooltipInfo && tooltipInfo?.properties.platform_type === 'sampling_location' && !isMobile && (
          <Popup
            tipSize={10}
            longitude={tooltipInfo.geometry.coordinates[0]}
            latitude={tooltipInfo.geometry.coordinates[1]}
            closeOnClick={false}
            closeButton={false}
            onClose={setTooltipInfo}
            className="omics-popup"
          >
            <OmicsDatasetSummary
              info={tooltipInfo}
              filters={omicsFilters}
              parameters={registeredParameters}
            />
          </Popup>
        )}
        {location.pathname !== '/landing' && tooltipInfo && tooltipInfo?.properties?.platform?.external_id_type === 'esp_site' && !isMobile && (
          <Popup
            tipSize={10}
            longitude={tooltipInfo.geometry.coordinates[0]}
            latitude={tooltipInfo.geometry.coordinates[1]}
            closeOnClick={false}
            closeButton={false}
            onClose={setTooltipInfo}
          >
            <ESPDatasetSummary
              info={tooltipInfo}
              parameters={espParameters}
              obsData={obsData}
            />
          </Popup>
        )}
        {
          location.pathname !== '/landing'
          && tooltipInfo
          && tooltipInfo?.properties.platform_type !== 'sampling_location'
          && tooltipInfo?.properties?.platform?.external_id_type !== 'esp_site'
          && !isMobile
          && (
            <Popup
              tipSize={10}
              longitude={tooltipInfo.geometry.coordinates[0]}
              latitude={tooltipInfo.geometry.coordinates[1]}
              closeOnClick={false}
              closeButton={false}
              onClose={setTooltipInfo}
            >
              <DatasetSummary
                info={tooltipInfo}
                parameters={filteredParameters}
                obsData={obsData}
              />
            </Popup>
          )
        }
        {selectedOverlay === 'surface_water_temperature'
          && (
            <SurfaceWaterTemperature
              UTCPath={activeUTCPath}
              selectedLake={selectedLake}
            />
          )}
        {selectedOverlay === 'surface_water_temperature' && contourInfo && !showDetail
          && (
            <Popup
              longitude={contourInfo.clickLon}
              latitude={contourInfo.clickLat}
              closeOnClick
              closeButton={false}
            >
              <div className="contour-popup-title">
                {preferredTemperatureUnit(contourInfo.feature.properties.level)}
              </div>
              <div className="contour-popup-text">
                {getDms(contourInfo.clickLat, contourInfo.clickLon)}
              </div>
            </Popup>
          )}

        <div id="currents" />

        {selectedOverlay === 'water_current'
          && (
            <WaterMagnitude
              UTCPath={activeUTCPath}
              selectedLake={selectedLake}
            />
          )}
        {selectedOverlay === 'water_current'
          && (
            <CurrentAnimation
              urlCoords={urlCoords}
              viewport={viewport}
              mapRef={mapRef}
              selectedLake={selectedLake}
              selectedForecast={selectedOverlay}
              UTCPath={activeUTCPath}
              isMobile={isMobile}
              location={location}
            />
          )}
        {selectedOverlay === 'water_current' && contourInfo && !showDetail
          && (
            <Popup
              longitude={contourInfo.clickLon}
              latitude={contourInfo.clickLat}
              closeOnClick
              closeButton={false}
            >
              <div className="contour-popup-title">
                {preferredWaterMagnitudeUnit(contourInfo.feature.properties.level)}
              </div>
              <div className="contour-popup-text">
                {getDms(contourInfo.clickLat, contourInfo.clickLon)}
              </div>
            </Popup>
          )}

        {selectedOverlay === 'wind_current'
          && (
            <WindMagnitude
              UTCPath={activeUTCPath}
              selectedLake={selectedLake}
            />
          )}
        {selectedOverlay === 'wind_current' && contourInfo && !showDetail
          && (
            <Popup
              longitude={contourInfo.clickLon}
              latitude={contourInfo.clickLat}
              closeOnClick
              closeButton={false}
            >
              <div className="contour-popup-title">
                {preferredWindMagnitudeUnit(contourInfo.feature.properties.level)}
              </div>
              <div className="contour-popup-text">
                {getDms(contourInfo.clickLat, contourInfo.clickLon)}
              </div>
            </Popup>
          )}

        {children}
      </ReactMapGL>
      {selectedOverlay === 'wind_current'
        && (
          <CurrentAnimation
            urlCoords={urlCoords}
            viewport={viewport}
            mapRef={mapRef}
            selectedLake={selectedLake}
            selectedForecast={selectedOverlay}
            UTCPath={activeUTCPath}
            isMobile={isMobile}
            location={location}
          />
        )}

      {['surface_water_temperature', 'water_current', 'wind_current'].find((overlay) => overlay === selectedOverlay)
        && location.pathname !== '/landing' && (
        <TimeSlider
          onSliderChange={handleSliderChange}
          selectedForecast={selectedOverlay}
        />
      )}

      {['omics'].find((overlay) => overlay === selectedOverlay.toLowerCase())
        && omicsFilters.filter
        && omicsFilters.startDate
        && omicsFilters.endDate
        && (
          <SelectedFilters
            filters={omicsFilters}
            type={selectedOverlay}
          />
        )}

    </>
  );
};

CustomMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  filters: PropTypes.object,
  selectedLake: PropTypes.string,
  selectedOverlay: PropTypes.string,
  platformEvents: PropTypes.array,
  selectedTags: PropTypes.array,
  assetTags: PropTypes.array,
  datasets: PropTypes.array,
  datasetSummaries: PropTypes.array,
  registeredParameters: PropTypes.array,
  observationalPlatforms: PropTypes.array,
  organizations: PropTypes.array,
  obsData: PropTypes.array,
  parameterConfigurations: PropTypes.array,
  filterVisible: PropTypes.bool,
  onMapClick: PropTypes.func,
  pushToHistory: PropTypes.func,
  onTagSelect: PropTypes.func,
  tagsList: PropTypes.object,
  filterFavorites: PropTypes.bool,
  favoritePlatformData: PropTypes.array,
  datasetTrajectories: PropTypes.object,
  showPaths: PropTypes.bool,
  omicsFilters: PropTypes.object,
  setOmicsFilters: PropTypes.func,
  obsDatasetsLatest: PropTypes.array,
  omicsParameterIds: PropTypes.array,
  parametersData: PropTypes.array,
};

CustomMap.defaultProps = {
  children: null,
  filters: {},
  selectedLake: '',
  selectedOverlay: '',
  platformEvents: [],
  selectedTags: [],
  assetTags: [],
  datasets: [],
  datasetSummaries: [],
  registeredParameters: [],
  observationalPlatforms: [],
  organizations: [],
  obsData: [],
  parameterConfigurations: [],
  filterVisible: false,
  onMapClick: () => {
  },
  pushToHistory: () => {
  },
  onTagSelect: () => {
  },
  tagsList: {},
  filterFavorites: false,
  favoritePlatformData: [],
  datasetTrajectories: {},
  showPaths: true,
  omicsFilters: {},
  setOmicsFilters: () => {},
  obsDatasetsLatest: [],
  omicsParameterIds: [],
  parametersData: [],
};

export default memo(CustomMap);
