import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal/Modal';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import { ParameterDetailsEvent } from '../../constant/google-analytics/constant';

import './styles.scss';

import { CloseIcon } from '../icons';

const CustomModal = (props) => {
  const {
    children, className, isSocialShare, isCommunityModal, ...rest
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Modal
      {...rest}
      mask
      maskStyle
      className={`custom-modal ${className}`}
      closeIcon={(
        <div onClick={isSocialShare ? () => ReactGA.event(ParameterDetailsEvent.ShareModalClosed) : () => {}}>
          <CloseIcon style={{ color: 'white', marginTop: (isMobile || isCommunityModal) ? '45px' : '30px', translate: '-20px' }} />
        </div>
      )}
      zIndex={1001}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isSocialShare: PropTypes.bool,
  isCommunityModal: PropTypes.bool,
};

CustomModal.defaultProps = {
  children: null,
  className: '',
  isSocialShare: false,
  isCommunityModal: false,
};

export default CustomModal;
