import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Radio, Switch } from 'antd';
import { SvgPinClickedIcon } from '../../../../components/icons';
import UserContext from '../../../../contexts/UserContext';

const UnitPreferenceForm = (props) => {
  const { unit } = props;
  const { unitPreferencesAction } = useContext(UserContext);
  const onPinChange = (unit, action) => {
    unitPreferencesAction({
      type: 'UNIT',
      unit: unit.name,
      pinned: action,
    });
  };
  const onChange = (e) => {
    unitPreferencesAction({
      type: 'UNIT',
      unit: unit.name,
      value: e.target.value,
    });
  };

  return (
    <>
      <div className="item-left">
        <div className="param-name">{unit.label}</div>
        <Radio.Group options={unit.options} value={unit.value} onChange={onChange} optionType="button" />
      </div>
      <div className="item-right" style={{ display: 'flex', justifyContent: 'right' }}>
        <Switch
          checked={unit.pinned}
          onChange={(action) => onPinChange(unit, action)}
          checkedChildren={<SvgPinClickedIcon style={{ color: '#fff' }} />}
          unCheckedChildren={<SvgPinClickedIcon style={{ color: '#777' }} />}
        />
      </div>
    </>
  );
};

UnitPreferenceForm.propTypes = {
  unit: PropTypes.object,
};

UnitPreferenceForm.defaultProps = {
  unit: {},
};

export default UnitPreferenceForm;
