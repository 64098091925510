import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card, Drawer, Col, Row, Input, Select, Typography, Button, Tooltip,
} from 'antd';
import {
  CaretDownFilled, LeftOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import lodash from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  greatLakes,
  statuses,
} from '../constant';
import CustomOption from './CustomOption';
import ParametersComponent from './Parameters';

import {
  CloseCircleIcon, CloseIcon, HeartIcon, MapViewInfoIcon,
} from '../../../components/icons';
import CustomTag from './CustomTag';
import FavoriteModal from './FavoriteModal';
import { featureFlag } from '../../../constant';
import ConfirmModal from '../../../components/confirm-modal';
import { brandingConfig } from '../../../config';
import OmicsData from '../../../components/custom-map/components/map-overlay-drawer/OmicsData';

const { Meta } = Card;
const { Link } = Typography;

const MapFilterPopup = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();

  const {
    editing,
    removedTag,
    selectedPlatform,
    selectedTags,
    selectedLake,
    visible,
    tagsList,
    platformEvents,
    platformsCount,
    onClose,
    onTagSelect,
    handleClearFilters,
    onLakeSelect,
    onStatusSelect,
    omicsFilters,
    setOmicsFilters,
  } = props;

  const [collapsed, setCollapsed] = useState(true);
  const onCollapse = () => setCollapsed(true);
  const [statusList, setStatusList] = useState(platformEvents.map((platform) => lodash.capitalize(platform.split('_').join(' '))));
  const [favoritesVisible, setFavoritesVisible] = useState();
  const [selectedLakeClass, setSelectedLakeClass] = useState({});
  const onCloseFavorites = () => setFavoritesVisible(false);
  const [platformName, setPlatformName] = useState(selectedPlatform.platform_name);
  const [platformSaved, setPlatformSaved] = useState(false);

  const container = useRef();

  const onTagRemove = useCallback(() => {
    const keys = Object.keys(selectedTags);
    for (let i = 0; i < keys.length; i++) {
      const tagIdx = selectedTags[keys[i]].indexOf(removedTag);

      if (tagIdx > -1) {
        selectedTags[keys[i]].splice(tagIdx, 1);
        break;
      }
    }
  }, [removedTag, selectedTags]);

  useEffect(() => {
    onTagRemove();
  }, [onTagRemove]);

  const onCloseFilter = () => {
    const tags = Object.keys(selectedTags).reduce(
      (prev, cur) => [...prev, ...selectedTags[cur]],
      [],
    );
    onClose({ tags, lake: selectedLake });
  };

  const handleClickStatus = (value, checked) => {
    let newStatuses = [...statusList];
    if (checked) {
      newStatuses.push(value);
    } else {
      newStatuses = newStatuses.filter((stat) => stat !== value);
    }

    setStatusList(newStatuses);
    onStatusSelect(newStatuses);
  };

  const onClearFilters = () => {
    setStatusList([]);
    setSelectedLakeClass({});
    handleClearFilters();
    setSelectedLakeClass({});
  };

  const handleLakeSelect = (e, lake) => {
    const selected = !e.currentTarget.classList.contains('selected') ? 'selected' : '';
    const lakeText = selected ? lake.text : '';

    onLakeSelect(lakeText);

    setSelectedLakeClass({ [lake.text]: selected });
  };

  useEffect(() => {
    if (selectedLake === '') {
      setSelectedLakeClass({});
    }
  }, [selectedLake]);

  const getLakeIcon = (icon) => {
    const IconComponent = icon;

    return <IconComponent />;
  };

  const showFavorites = () => {
    setFavoritesVisible(true);
  };

  const handleSavePlatform = () => {
    if (platformName) {
      setPlatformSaved(true);
    }
  };

  const isOmicsTagSelected = selectedTags?.weather?.some((tag) => tag.key.includes('omics'));

  return (
    <>
      <div className="map-filter-drawer-container">
        <Drawer
          className="map-filter-drawer"
          placement="left"
          closable={!editing || isMobile}
          visible={visible}
          getContainer={false}
          width="35%"
          onClose={onCloseFilter}
          closeIcon={isMobile ? (
            <CloseCircleIcon />
          ) : (
            <span style={{ color: 'rgb(230, 230, 230)', fontSize: 24 }}>
              <CloseIcon />
            </span>
          )}
          title={(
            editing
              ? (
                <div className="d-flex" style={{ alignItems: 'center', fontWeight: 500 }}>
                  {`Editing your saved Location and Filter '${selectedPlatform.platform_name}'`}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RouterLink to="/profile/favorites" style={{ color: '#fff' }}>Cancel</RouterLink>
                    &nbsp;
                    <Button
                      className="h3-w"
                      style={{
                        borderRadius: 20,
                        backgroundColor: brandingConfig.colors.accent1,
                        color: brandingConfig.colors.buttonText,
                        border: 'none',
                      }}
                      onClick={handleSavePlatform}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )
              : (
                <div>
                  {!collapsed ? <Button type="link" icon={<LeftOutlined />} onClick={onCollapse} /> : null}
                  Filters
                  {!isMobile && (
                    <Link style={{ color: '#fff', float: 'right', marginRight: '7%' }} onClick={onClearFilters}>
                      Clear All
                    </Link>
                  )}

                  {isMobile && visible && !favoritesVisible && featureFlag.filterFavorites && (
                    <Button
                      className="favorite-button"
                      icon={<HeartIcon />}
                      shape="circle"
                      onClick={showFavorites}
                    />
                  )}
                </div>
              )
          )}
          footer={isMobile ? (
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <Link
                style={{ color: '#fff', fontWeight: 500 }}
                onClick={onClearFilters}
              >
                Clear All
              </Link>
              <Button
                onClick={onClose}
                shape="round"
                size="large"
                style={{
                  backgroundColor: brandingConfig.colors.accent1,
                  color: brandingConfig.colors.buttonText,
                  fontWeight: 500,
                  fontSize: 15,
                  border: 'none',
                }}
              >
                {`Show ${platformsCount} Platforms`}
              </Button>
            </div>
          ) : null}
        >
          {collapsed
            ? (
              <div ref={container}>
                {editing
                  && (
                    <div className="filter-content" style={{ paddingBottom: 30, paddingTop: 0 }}>
                      <label className="h3">Name</label>
                      <Input
                        size="large"
                        style={{ backgroundColor: brandingConfig.colors.greyLight, borderRadius: 20 }}
                        defaultValue={selectedPlatform.platform_name}
                        onChange={(e) => setPlatformName(e.target.value)}
                      />
                    </div>
                  )}
                <div className="filter-content" style={{ paddingTop: 0 }}>
                  <label className="h3">Location</label>
                  <p>Select a Great Lake to narrow your focus</p>
                  <Row gutter={[{ xs: 32 }, { xs: 16, lg: 16 }]} style={{ paddingBottom: 13 }}>
                    {greatLakes.map((lake, index) => (
                      <Col xl={{ span: 4, offset: index > 0 ? 1 : 0 }} lg={7} xs={7} key={lake.text}>
                        <Card
                          bordered={false}
                          onClick={(e) => handleLakeSelect(e, lake)}
                          className={lodash.isEmpty(selectedLakeClass) && selectedLake === lake.text ? 'selected' : selectedLakeClass[lake.text]}
                          data-testid={`${lake.text}-lake-card`}
                        >
                          {getLakeIcon(lake.icon)}
                          <Meta title={lake.text} style={{ fontSize: 13 }} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="filter-content">
                  <label className="h3">Observation Platforms</label>
                  <p>Select different data-collecting platforms</p>
                  <Row gutter={[12, 20]} className="platform-filters">
                    <Col xl={12} lg={16} xs={20}>
                      <Select
                        dropdownClassName="filter-status-select"
                        mode="multiple"
                        placeholder="Status"
                        style={{ width: '100%' }}
                        showArrow
                        suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
                        value={statusList}
                        onDeselect={(value) => handleClickStatus(value, false)}
                        showSearch={false}
                        dropdownRender={() => (
                          statuses.map((status) => (
                            <CustomOption
                              key={status.title}
                              text={status.title}
                              prefix={status.icon}
                              selected={statusList.indexOf(status.title) > -1}
                              onClick={() => handleClickStatus(status.title, statusList.indexOf(status.title) === -1)}
                            />
                          ))
                        )}
                      />
                    </Col>
                    <Col span={24} style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {tagsList?.platformTags?.map((platformTag, index) => (
                        <CustomTag
                          key={index}
                          index={index}
                          platformTag={platformTag}
                          onTagSelect={onTagSelect}
                          selectedTags={selectedTags?.platforms}
                          // unsure why, but using template literal causes linting error that disabling next line does not get around
                          // eslint-disable-next-line prefer-template
                          disabled={'filter_' + platformTag.key in featureFlag ? !featureFlag['filter_' + platformTag.key] : false}
                          tagCategory="platforms"
                        />
                      ))}
                    </Col>
                  </Row>
                </div>
                {isMobile
                  && (
                    <div className="filter-content">
                      <label>
                        <span className="h3">Forecast</span>
                        &nbsp;
                        <Tooltip
                          placement="right"
                          title="NOAA model data are generated daily to portray current and future predictions."
                        >
                          <MapViewInfoIcon />
                        </Tooltip>
                      </label>
                      <p>Discover interactive data layers</p>
                      <div>
                        {tagsList?.weatherTags?.map((weatherTag, index) => (
                          <span key={index}>
                            <CustomTag
                              index={index}
                              platformTag={weatherTag}
                              onTagSelect={onTagSelect}
                              selectedTags={selectedTags?.weather}
                              tagCategory="weather"
                            />
                          </span>
                        ))}
                        <Tooltip
                          defaultOpen
                          overlayClassName="omics-data-tooltip"
                          placement="left"
                          title="See the biological composition of sampling sites in Lake Erie. Start by selecting this layer and setting the date range you wish to see."
                        >
                          <MapViewInfoIcon className="info-button" style={{ marginLeft: '-10px' }} />
                        </Tooltip>
                        {featureFlag.omics && isOmicsTagSelected
                          && (
                            <OmicsData
                              omicsFilters={omicsFilters}
                              setOmicsFilters={setOmicsFilters}
                            />
                          )}
                      </div>
                    </div>
                  )}
                <div className="filter-content">
                  <label>
                    <span className="h3">Water Quality</span>
                  </label>
                  <p>See areas affected by harmful algal blooms (HABs)</p>
                  <div>
                    {tagsList?.waterTags?.map((waterTag, index) => (
                      <span key={index}>
                        <CustomTag
                          index={index}
                          platformTag={waterTag}
                          onTagSelect={onTagSelect}
                          selectedTags={selectedTags?.water}
                          tagCategory="water"
                          // eslint-disable-next-line prefer-template
                          disabled={'filter_' + waterTag.key in featureFlag ? !featureFlag['filter_' + waterTag.key] : false}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )
            : <ParametersComponent collapsed={collapsed} />}
        </Drawer>
      </div>
      {!isMobile && visible && !favoritesVisible && featureFlag.filterFavorites && !editing
        && (
          <Button
            className="favorite-button"
            icon={<HeartIcon />}
            shape="circle"
            onClick={showFavorites}
          />
        )}

      <FavoriteModal visible={favoritesVisible} onClose={onCloseFavorites} />
      <ConfirmModal
        visible={platformSaved}
        removed={platformSaved}
        removedTxt="Platform successfully removed from favorites"
        onCancel={() => history.push('/profile/favorites')}
      />
    </>
  );
};

MapFilterPopup.propTypes = {
  editing: PropTypes.bool,
  visible: PropTypes.bool,
  selectedLake: PropTypes.string,
  removedTag: PropTypes.object,
  selectedTags: PropTypes.object,
  tagsList: PropTypes.object,
  platformEvents: PropTypes.array,
  platformsCount: PropTypes.number,
  selectedPlatform: PropTypes.object,
  onClose: PropTypes.func,
  onTagSelect: PropTypes.func,
  handleClearFilters: PropTypes.func,
  onLakeSelect: PropTypes.func,
  onStatusSelect: PropTypes.func,
  omicsFilters: PropTypes.object,
  setOmicsFilters: PropTypes.func,
};

MapFilterPopup.defaultProps = {
  editing: false,
  visible: false,
  selectedLake: null,
  removedTag: null,
  selectedTags: {},
  tagsList: {},
  platformEvents: [],
  platformsCount: 0,
  selectedPlatform: {},
  onClose: () => { },
  onTagSelect: () => { },
  handleClearFilters: () => { },
  onLakeSelect: () => { },
  onStatusSelect: () => { },
  omicsFilters: {},
  setOmicsFilters: () => { },
};

export default memo(MapFilterPopup);
